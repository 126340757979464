<div class="orders-list">
  <div class="orders-list-title">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="back d-flex align-items-center">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer  d-md-block d-none"
               [routerLink]="['/admin', 'dashboard']"/>
          <p class="title-back mb-0 fs-16 cursor-pointer  d-md-block d-none"
             [routerLink]="['/admin', 'dashboard']">{{'back' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="orders-list-wrapper" >
    <h2 class="fs-32 fw-bold ps-sm-0 ps-4">{{'rfq_negotiation' | translate}}</h2>
    <div class="custom-table">
      <kwot-custom-table
        #customTableComponent
        [customOptions]="customDatatableSettings"
        [ajaxFunction]="getNegotiations"
        [tableHead]="tableHeadNegotiation" [tableBody]="tableBodyNegotiation">
      </kwot-custom-table>
      <ng-template #tableHeadNegotiation>
        <thead *ngIf="!isMobile">
        <tr>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'rfq_type' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'date' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'status' | translate}}
            </p>
          </td>
        </tr>
        </thead>
      </ng-template>
      <ng-template #tableBodyNegotiation>
        <tbody *ngIf="negotiationList.length <= 0">
        <tr>
          <td colspan="7">{{'no_data_found' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="negotiationList.length > 0  && !isMobile">
        <tr *ngFor="let negotiation of negotiationList">
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details text-capitalize"
               [routerLink]="['/admin' ,'rfq-negotiation', negotiation._id]">
              {{negotiation?.negotiationOfferType}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details">
              {{negotiation.createdAt | date: 'dd/MM/yyyy'}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
                      <span class="status rfq-negotiation" *ngIf="negotiation?.status === 'initiated' || negotiation?.status === 'in negotiation'">
                        <span class="badge"></span> {{'rfq_negotiation' | translate}}
                      </span>
              <span class="status delivered" *ngIf="negotiation?.status === 'approved by admin'">
                        <span class="badge"></span> {{'approved' | translate}}
                      </span>
            </p>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="negotiationList.length > 0  && isMobile">
        <tr *ngFor="let negotiation of negotiationList">
          <td colspan="9" class="order-details w-100 p-3">
            <div>
              <p class="mb-0 fs-16 cursor-pointer store-content-details text-capitalize"
                 [routerLink]="['/admin' ,'rfq-negotiation', negotiation._id]">
                {{negotiation?.negotiationOfferType}}
              </p>
            </div>
            <div>
              <p class="mb-0 fs-16 store-content-details ms-2">
                {{negotiation?.createdAt | date: 'dd/MM/yyyy'}}
              </p>
            </div>
            <div>
              <p class="mb-0 fs-16 store-content-details">
                      <span class="status rfq-negotiation" *ngIf="negotiation?.status === 'initiated' || negotiation?.status === 'in negotiation'">
                        <span class="badge"></span> {{'rfq_negotiation' | translate}}
                      </span>
                <span class="status delivered" *ngIf="negotiation?.status === 'approved by admin'">
                        <span class="badge"></span> {{'approved' | translate}}
                      </span>
              </p>
            </div>
          </td>
        </tr>
        </tbody>
      </ng-template>
    </div>
  </div>
</div>
