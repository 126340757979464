import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {User} from "@kwot/data-models";
import {LocalstorageService} from "@kwot/app-config";

@Component({
  selector: 'kwot-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  currentRoute: string;
  @Input() currentUser: User;
  permission: any[] = [];
  isB2B: boolean = false;

  constructor(
    private router: Router,
    private localStorageService: LocalstorageService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[1]) {
          this.currentRoute = url[1];
        } else {
          this.currentRoute = 'dashboard';
        }
      }
    })
  }

  ngOnInit(): void {
    this.isB2B = this.localStorageService.updateIsB2B('get');
  }

  checkData(menu: any) {
    if (this.currentUser.role !== 'MANAGEMENT' && this.currentUser.role !== 'IT') {
      return this.currentUser.permissions.some((item: any) => item.menu === menu);
    } else {
      return this.currentUser.role === 'MANAGEMENT' || this.currentUser.role === 'IT';
    }
  }

}
