import {
  AdminDashboard,
  PaginatedOrders,
  PayoutDetails
} from "@kwot/data-models";
import {Action, createReducer, on} from "@ngrx/store";
import {
  Dashboard,
  DashboardError,
  DashboardSuccess,
  OrdersList,
  OrdersListError,
  OrdersListSuccess,
  ResetAdminState,
  GetCsvFile,
  GetCsvFileSuccess,
  GetCsvFileError,
  GetPayoutList,
  GetPayoutListError,
  GetPayoutListSuccess,
  UpdatePayoutStatus,
  UpdatePayoutStatusSuccess,
  UpdatePayoutStatusError,
  GetReviews,
  GetReviewsSuccess,
  GetReviewsError,
  DeleteReviewsSuccess,
  GetUserCsvFileError,
  GetUserCsvFileSuccess,
  GetUserCsvFile,
  UserCarts,
  UserCartsSuccess,
  UserCartsError,
  GetNegotiations,
  GetNegotiationsError,
  GetNegotiationsSuccess
} from "./admin.actions";
export interface AdminState {
  success: string;
  error: string;
  dashboard: AdminDashboard | null;
  orders: PaginatedOrders | null;
  payout: PayoutDetails,
  reviews: any,
  carts: any,
  negotiations: any[],
}

const initAdminState: AdminState = {
  error: '', success: '', dashboard: null, reviews: null, payout: null,
  orders: null, carts: null, negotiations: []
}

const adminReducer = createReducer(initAdminState,
  on(Dashboard, (state) => ({
    ...state,
    dashboard: null,
    error: '',
    success: ''
  })),
  on(DashboardSuccess, (state, {dashboard}) => ({
    ...state,
    dashboard,
    error: '',
    success: ''
  })),
  on(DashboardError, (state, {error}) => ({
    ...state,
    dashboard: null,
    error,
    success: ''
  })),

  on(OrdersList, (state) => ({...state, orders: null, error: '', success: ''})),
  on(OrdersListSuccess, (state, {orders}) => ({...state, orders, error: '', success: ''})),
  on(OrdersListError, (state, {error}) => ({...state, orders: null, error, success: ''})),

  on(GetCsvFile, (state) => ({...state, error: '', success: ''})),
  on(GetCsvFileSuccess, (state) => ({...state, error: '', success: ''})),
  on(GetCsvFileError, (state, {error}) => ({...state, error, success: ''})),

  on(GetPayoutList, (state) => ({...state, payout: null, error: '', success: ''})),
  on(GetPayoutListSuccess, (state, {payout}) => ({...state, payout, error: '', success: ''})),
  on(GetPayoutListError, (state, {error}) => ({...state, error, success: ''})),

  on(UpdatePayoutStatus, (state) => ({...state, payout: null, error: '', success: ''})),
  on(UpdatePayoutStatusSuccess, (state, {payout}) => ({...state, payout, error: '', success: ''})),
  on(UpdatePayoutStatusError, (state, {error}) => ({...state, error, success: ''})),

  on(GetReviews, (state) => ({...state, reviews: null, error: '', success: ''})),
  on(GetReviewsSuccess, (state, {reviews}) => ({...state, reviews, error: '', success: ''})),
  on(GetReviewsError, (state, {error}) => ({...state, reviews: null, error, success: ''})),

  on(GetUserCsvFile, (state) => ({...state, error: '', success: ''})),
  on(GetUserCsvFileSuccess, (state) => ({...state, error: '', success: ''})),
  on(GetUserCsvFileError, (state, {error}) => ({...state, error, success: ''})),

  on(UserCarts, (state) => ({...state, error: '', success: '', carts: null})),
  on(UserCartsSuccess, (state, {carts}) => ({...state, error: '', success: '', carts})),
  on(UserCartsError, (state, {error}) => ({...state, error, success: '', carts: null})),

  on(DeleteReviewsSuccess, (state, payload) => {
    let reviews = state.reviews?.data?.filter((item: any) => item._id !== payload.id);
    let data = {
      count: state.reviews.count,
      data: [...reviews]
    }
    return {
      ...state,
      reviews: data,
      error: '',
      success: '',
    };
  }),

  on(GetNegotiations, (state) => ({...state, negotiations: null, error: '', success: ''})),
  on(GetNegotiationsSuccess, (state, {negotiations}) => ({...state, error: '', success: '', negotiations})),
  on(GetNegotiationsError, (state, {error}) => ({...state, orders: null, error, success: ''})),

  on(ResetAdminState, (state, {params}) => ({
    ...state,
    ...params
  }))
);

export function reducer(state = initAdminState, action: Action) {
  return adminReducer(state, action);
}
