<div class="header">
  <div>
    <div class="d-flex">
      <div class="header-logo">
        <div class="logo-content">
          <div class="dropdown ">
            <button class="btn d-md-flex align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              <img class="logo me-3 cursor-pointer" src="/assets/images/Logo.svg" alt="" *ngIf="userType === 'admin'">
              <ng-container *ngIf="userType === 'vendor'">
                <img class="vendor-logo cursor-pointer"
                     [lazyLoad]="currentUser?.logo" defaultImage="/assets/images/logo-small.svg"
                     alt="">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <span [title]="currentUser?.storeName">{{currentUser?.storeName}}</span>
                    <img class="logo ms-2" *ngIf="currentUser?.isAdminVerified === 'ACCEPTED'"
                         src="/assets/images/icons/store-check.svg" alt="">
                  </div>
                  <img src="/assets/images/arrow.png" class="arrow cursor-pointer" alt="">
                </div>

              </ng-container>

            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <ng-container *ngIf="userType === 'admin'">
                <div class="dropdown-item">
                  <img class="logo me-3" src="/assets/images/logo-small.svg" alt="">
                  <p class="mb-0">{{'go_to_value' | translate: {value: 'Kwot'} }}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="userType === 'vendor'">
                <div class="dropdown-item vendor-item">
                  <div class="content justify-content-between">
                    <div class="vendor-details d-flex align-items-center">
                      <img class="vendor-logo me-3" src="/assets/images/logo-small.svg" alt="">
                      <div class="vendor-info">
                        <p class="mb-0">{{currentUser?.name}}</p>
                        <span>{{currentUser?.email}}</span>
                      </div>
                    </div>
                    <div class="text-start d-flex align-items-center">
                      <label class="action-switch-green-btn me-2 m-0">
                        <input type="checkbox" disabled [checked]="currentUser?.isAdminVerified === 'ACCEPTED'">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="header-right-menu ps-md-5 w-100">
        <div class="left-header d-md-block d-none">
          <div class="form-group mb-0">
            <div class="position-relative">
              <img src="/assets/images/icons/search-icon.svg" class="search-icon" alt="">
              <input type="search" class="form-control br-16 search-input" placeholder="{{'search' | translate}}">
            </div>
          </div>
        </div>
        <div class="right-header">
          <ng-container [ngTemplateOutlet]="helpDesk" *ngIf="helpDesk"></ng-container>
          <div class="text-start d-flex align-items-center">
            <div class="fs-16 fw-bold need-help me-2 text-center">{{'switch_to' | translate}} {{!isB2B ? ('b2b' | translate) : ('b2c' | translate)}}</div>
            <label class="action-switch-green-btn m-0">
              <input type="checkbox" (click)="enableChanged($event)" [checked]="isB2B">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="notification-drop dropdown cursor-pointer" (click)="openDropDown()">
            <div class="notification" id="notification-dropdown" data-bs-toggle="dropdown"
                 [ngClass]="{'px-32' : currentUser?.image, 'px-24': !currentUser?.image}">
              <div class="" [ngClass]="{'' : notificationSeen == true, 'img-wrapper' : notificationSeen == false}">
                <img class="" src="/assets/images/notification-bell.svg" alt="">
              </div>
            </div>
            <div class="dropdown-menu" id="account-menu" aria-labelledby="profile-dropdown">
              <div class="title d-flex align-items-center">
                <span class="cursor-pointer search" *ngIf="viewNotification" (click)="viewNotification = null">
                      <img src="assets/images/icons/back.svg" alt=""/>
                </span>
                <h5 class="fs-12 fw-bold" [ngClass]="{'mb-0 ms-5': viewNotification}">
                  {{'notifications' | translate}}
                </h5>
              </div>
              <ng-container *ngIf="viewNotification; else showOptions">
                <div class="notification-drop-menu">
                  <h4 class="fw-bold fs-16">{{viewNotification?.title || 'Kwot'}}</h4>
                  <p class="fs-10 mb-2">{{viewNotification?.createdAt | date: 'dd/MM/yyyy hh:mm aa'}}</p>
                  <h6 class="fs-14">
                    {{viewNotification?.message}}
                  </h6>
                </div>
              </ng-container>
              <ng-template #showOptions>
                <ng-container *ngIf="notifications.length <= 0; else showNotifications">
                  <div class="no-notification">
                    <img class="dark-icon me-3" src="/assets/images/icons/empty-box.svg" alt=""/>
                    <h6 class="fw-medium subtitle fw-bold subtitle-margin">
                      {{'no_notifications' | translate}}
                    </h6>
                  </div>
                </ng-container>
                <ng-template #showNotifications>
                  <div class="notification-drop-menu" (click)="showNotification(notification)"
                       [ngClass]="{'unread': !notification?.isRead, 'read': notification?.isRead}"
                       *ngFor="let notification of notifications">
                    <h4 class="fw-bold fs-16">
                      <span></span>
                      {{notification?.title || 'Kwot'}}
                    </h4>
                    <p class="fs-10 mb-2">
                      {{notification?.createdAt | date: 'dd/MM/yyyy hh:mm aa'}}
                    </p>
                    <h6 class="fs-12">
                      {{notification?.message}}
                    </h6>
                  </div>
                  <div class="load-more" (click)="loadNotifications()"
                       *ngIf="totalNotificationCount !== notifications.length">
                    {{'load_more' | translate}}
                  </div>
                </ng-template>
              </ng-template>
              <!--              <div class="notification-drop-menu">-->
              <!--                <h4 class="fw-bold fs-16">-->
              <!--                  <span></span>-->
              <!--                  Notification title-->
              <!--                </h4>-->
              <!--                <p class="fs-10 mb-2">-->
              <!--                  13:30 | Today-->
              <!--                </p>-->
              <!--                <h6 class="fs-12 ">-->
              <!--                  Description message goes here an can be longer...-->
              <!--                </h6>-->
              <!--              </div>-->
            </div>
          </div>

          <div class="dropdown profile-drop">
            <div class="user-profile" id="profile-dropdown" data-bs-toggle="dropdown"
                 [ngClass]="{'no-image' : !currentUser?.image}">
              <img class="user" *ngIf="currentUser?.image" [lazyLoad]="currentUser?.image" alt="">
              <span *ngIf="!currentUser?.image">{{logoName | uppercase}}</span>
            </div>
            <div class="dropdown-menu" aria-labelledby="profile-dropdown">
              <ul>
                <li>
                  <a [routerLink]="['/', appConfig.type, 'profile']">
                    <img class="dark-icon me-3" src="/assets/images/icons/user-profile.svg" alt="">
                    {{ 'my_account' | translate }}</a>
                </li>
                <li *ngIf="userType === 'vendor'">
                  <a [routerLink]="['/', appConfig.type, 'store-settings']">
                    <img class="dark-icon me-3" src="/assets/images/icons/sales-light-icon.svg" alt="">
                    {{ 'my_store' | translate }}</a>
                </li>
<!--                <li *ngIf="userType === 'vendor'">-->
<!--                  <a [href]="appConfig.buyerUrl + '/user/store/' + currentUser?._id" target="_blank">-->
<!--                    <img class="dark-icon me-3" src="/assets/images/icons/sales-light-icon.svg" alt="">-->
<!--                    See My Public Profile</a>-->
<!--                </li>-->
                <li class="sign-out cursor-pointer">
                  <a (click)="logoutUser()">{{ 'sign_out' | translate }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Live chat-->
<kwot-chat></kwot-chat>

