import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Order, OrderStatusText, ReturnOrderStatusText} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AdminState} from "../../+state/admin.reducer";
import {GetNegotiations, OrdersList, ResetAdminState} from "../../+state/admin.actions";
import {getAllOrders, getNegotiations} from "../../+state/admin.selectors";
import {TranslateService} from "@ngx-translate/core";
import {APP_CONFIG, LocalstorageService, WindowService} from "@kwot/app-config";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'kwot-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, OnDestroy {

  ordersList: Order[] = [];
  negotiationList: any = [];
  filters: any[] = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'shipped',
      label: 'Shipped',
    },
    {
      value: 'return',
      label: 'Return request',
    },
    {
      value: 'canceled',
      label: 'Canceled',
    },
    {
      value: 'refund',
      label: 'Refund Pending',
    },
  ];
  selectedFilter: string | number = '';
  unsubscriber = new Subject();
  customDatatableSettings: any = {};
  orderStatus = OrderStatusText;
  returnOrderStatus = ReturnOrderStatusText;
  isMobile: boolean;
  screenSize: number;
  queryParams: any;
  dataCount = 50000000000;
  firstTime = true;
  selectedTab: string = 'orders';
  isB2B: boolean = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private adminStore: Store<AdminState>,
    private translateService: TranslateService,
    private windowService: WindowService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalstorageService
  ) {
    this.adminStore.dispatch(ResetAdminState({params: {error: '', success: '', orders: null}}));
    this.activeRoute.queryParams.pipe(takeUntil(this.unsubscriber))
      .subscribe(query => {
        if (query.filter) {
          this.selectedFilter = query.filter
        }
        this.queryParams = {
          search: query.search || '',
          page: query.page ? (parseInt(query.page) === 0 ? 1 : parseInt(query.page)) : 1,
          order: query.order
        };
        this.customDatatableSettings = {
          ...this.customDatatableSettings,
          search: {
            search: query.search || ''
          }
        }
        this.getData((this.queryParams.page - 1), query.perPage, query.search, query.order ? JSON.parse(query.order) : {});
      })
    this.subscribeToStore();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
    this.activeRoute.queryParams.subscribe(query => {
      if (query.return) {
        this.selectedFilter = 'return'
      }
    })
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getAllOrders))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(orders => {
        if (orders) {
          this.ordersList = [...orders.data.map(t => {
            let discountAmount = 0;
            if (t.productDiscount) {
              if (t.productDiscount?.discountType === 'percentage') {
                discountAmount = Number(((t.productOriginalPrice) * (t.productDiscount.discountValue || 0)) / 100)
              } else {
                discountAmount = Number(t.productDiscount.discountValue || 0)
              }
            }
            return {...t, discountAmount};
          })];
          this.dataCount = orders.count?.total;
        }
      })

    this.adminStore.pipe(select(getNegotiations))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((negotiations: any) => {
        if (negotiations) {
          this.negotiationList = negotiations;
        }
      })
  }

  ngOnInit(): void {
    this.isB2B = this.localStorageService.updateIsB2B('get');
    if (this.selectedTab === 'orders') {
      this.customDatatableSettings = {
        ...this.customDatatableSettings,
        paging: false,
        serverSide: true,
        columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
        addAjax: true,
        order: [[2, 'asc']],
        searchPlaceholder: this.translateService.instant('search_by', {field: 'seller'}),
        orderColumns: ['buyer', 'seller', 'order', 'date_time', 'status', 'products', 'price']
      }
    }

    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
  }

  getAllOrders = (search = '', order = {}) => {
    if (!this.firstTime) {
      this.router.navigate(['/admin', 'orders'],
        {
          queryParamsHandling: 'merge',
          queryParams: {
            ...this.queryParams,
            search,
            order: JSON.stringify(order)
          }
        }
      )
    }

    this.firstTime = false;
  }

  getData(page = 0, perPage = 10, search = '', order = {}) {
    this.adminStore.dispatch(OrdersList({
      params: {
        page, limit: perPage,
        ...(search ? {search} : {}),
        ...(this.selectedFilter ? {criteria: this.selectedFilter} : {}),
        ...order
      }
    }))
  }

  filterOrders(value: string | number) {
    this.selectedFilter = value
    this.router.navigate(['/admin', 'orders'], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...this.queryParams,
        page: 0,
        perPage: 10,
        search: '',
        order: JSON.stringify({}),
        filter: this.selectedFilter
      }
    })
  }

  changePage(event: any) {
    this.router.navigate(['/admin', 'orders'], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...this.queryParams,
        page: event.page
      }
    })
  }

  getDiscount(item: any){
    let discountPrice;
    const price = Number(item?.productOriginalPrice) + Number(item?.platformFee);
    if (item?.productDiscount) {
      if (item?.productDiscount?.discountType === 'percentage') {
        discountPrice = Number(price - Number((price * (item?.productDiscount?.discountValue || 0)) / 100))
      } else {
        discountPrice = Number(price - Number(item?.productDiscount?.discountValue || 0))
      }
    }
    return discountPrice;
  }

  selectTab (type: string) {
    this.selectedTab = type;
  }

  getNegotiations(type: string) {
    this.selectedTab = type;
    this.customDatatableSettings = {
      paging: false,
      serverSide: true,
      searching: false,
      columns: [{data: null}, {data: null}, {data: null}],
      addAjax: true,
      order: [[2, 'asc']],
      orderColumns: ['type', 'date', 'status']
    }
    this.adminStore.dispatch(GetNegotiations({ params: { isAdmin: true }}))
  }

  getMessage(negotiation: any) {
    return negotiation[0].message;
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }
}
