import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AdminService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.ApiUrl = `${environment.apiUrl}/${environment.type}`;
  }

  getDashboard(params: any) {
    return this.http.get(`${this.ApiUrl}/dashboard`, {params});
  }

  getPendingStoreList(params: any = {}) {
    return this.http.get(`${this.ApiUrl}/getAllUnVerifiedVendor`, {params});
  }

  getAllOrders(params: any = {}) {
    return this.http.get(`${this.ApiUrl}/getOrderList`, {params});
  }

  getWalletList(params: any) {
    return this.http.get(`${this.ApiUrl}/getWalletList`, {params});
  }

  getCsvFile(params: any, type: any) {
    return this.http.get(`${this.ApiUrl}/getCsvFile`, {params: {currencyRate: params, type}});
  }

  getPayout(params: any) {
    return this.http.get(`${this.ApiUrl}/getPayout`, {params});
  }

  updatePayoutStatus(params: any) {
    return this.http.post(`${this.ApiUrl}/updatePayoutStatus`, params);
  }

  getReviews(params: any) {
    return this.http.get(`${this.ApiUrl}/reviews`, {params});
  }

  deleteReview(params: any) {
    return this.http.delete(`${this.ApiUrl}/deleteReview`, {params});
  }

  getUserCsvFile(params: any) {
    return this.http.get(`${this.ApiUrl}/getUserCsvFile`, {params});
  }

  getUserCarts(params: any) {
    return this.http.get(`${this.ApiUrl}/getUserCarts`, {params});
  }

  getNegotiationList(params: any) {
    return this.http.get(`${this.ApiUrl}/getNegotiation`, {params});
  }
}
