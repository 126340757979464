import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {HeaderComponent} from "./components/header/header.component";
import {RouterModule} from "@angular/router";
import {SharedModule} from "@kwot/shared";
import {StoreModule} from "@ngrx/store";
import * as fromAdminVendor from "./+state/admin-vendor.reducer";
import {EffectsModule} from "@ngrx/effects";
import {AdminVendorEffects} from "./+state/admin-vendor.effects";
import {CustomTableModule} from "@kwot/custom-table";
import {SelectModule} from "@kwot/select";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    CustomTableModule,
    StoreModule.forFeature('admin-vendor', fromAdminVendor.reducer),
    EffectsModule.forFeature([AdminVendorEffects]),
    SelectModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class AdminVendorSharedModule {
}
