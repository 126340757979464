import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// import {Order, OrderStatusText, ReturnOrderStatusText} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AdminState} from "../../+state/admin.reducer";
import {GetNegotiations, ResetAdminState} from "../../+state/admin.actions";
import {getNegotiations} from "../../+state/admin.selectors";
import {TranslateService} from "@ngx-translate/core";
import {APP_CONFIG, LocalstorageService, WindowService} from "@kwot/app-config";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'kwot-rfqs-list',
  templateUrl: './rfqs-list.component.html',
  styleUrls: ['./rfqs-list.component.scss']
})
export class RfqsListComponent implements OnInit, OnDestroy {
  negotiationList: any = [];
  unsubscriber = new Subject();
  customDatatableSettings: any = {};
  isMobile: boolean;
  screenSize: number;
  queryParams: any;
  dataCount = 50000000000;
  firstTime = true;
  isB2B: boolean = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private adminStore: Store<AdminState>,
    private translateService: TranslateService,
    private windowService: WindowService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalstorageService,
  ) {
    this.adminStore.dispatch(ResetAdminState({params: {error: '', success: '', orders: null}}));
    this.activeRoute.queryParams.pipe(takeUntil(this.unsubscriber))
      .subscribe(query => {
        this.queryParams = {
          search: query.search || '',
          page: query.page ? (parseInt(query.page) === 0 ? 1 : parseInt(query.page)) : 1,
          order: query.order
        };
        this.customDatatableSettings = {
          ...this.customDatatableSettings,
          search: {
            search: query.search || ''
          }
        }
        this.getData((this.queryParams.page - 1), query.perPage, query.search);
      })
    this.subscribeToStore();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getNegotiations))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((negotiations: any) => {
        if (negotiations) {
          this.negotiationList = negotiations;
        }
      })
  }

  ngOnInit(): void {
    this.isB2B = this.localStorageService.updateIsB2B('get');
    this.customDatatableSettings = {
      ...this.customDatatableSettings,
      paging: false,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      searchPlaceholder: this.translateService.instant('search_by', {field: 'rfqs'}),
      orderColumns: ['type', 'date', 'status'],
      order: [[2, 'desc']]
    }
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
  }

  getNegotiations = (search = '') => {
    if (!this.firstTime) {
      this.router.navigate(['/admin', 'rfqs'],
        {
          queryParamsHandling: 'merge',
          queryParams: {
            ...this.queryParams,
            search
          }
        }
      )
    }
    this.firstTime = false;
  }

  getData(page = 0, perPage = 10, search = '') {
    this.adminStore.dispatch(GetNegotiations({
      params: {
        isAdmin: true,
        page, limit: perPage,
        ...(search ? {search} : {}),
      }
    }))
  }

  changePage(event: any) {
    this.router.navigate(['/admin', 'rfqs'], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...this.queryParams,
        page: event.page
      }
    })
  }

  getDiscount(item: any){
    let discountPrice;
    const price = Number(item?.productOriginalPrice) + Number(item?.platformFee);
    if (item?.productDiscount) {
      if (item?.productDiscount?.discountType === 'percentage') {
        discountPrice = Number(price - Number((price * (item?.productDiscount?.discountValue || 0)) / 100))
      } else {
        discountPrice = Number(price - Number(item?.productDiscount?.discountValue || 0))
      }
    }
    return discountPrice;
  }

  getMessage(negotiation: any) {
    return negotiation[0].message;
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }
}
