import {createFeatureSelector, createSelector} from "@ngrx/store";
import {VendorBuyerState} from "./vendor-buyer.reducer";

const getVendorBuyerState = createFeatureSelector<VendorBuyerState>('vendor-buyer');

export const getVendorBuyerSuccess = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.success);
export const getVendorBuyerError = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.error);
export const getPolicyData = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.policy);
export const getNegotiationDetails = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.negotiationDetails);
export const getNegotiationInfo = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.negotiation);
export const getProposalVendorList = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.proposalList)
export const getProducts = createSelector(getVendorBuyerState, (state: VendorBuyerState) => state.products);
