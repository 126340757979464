import {Action, createReducer, on} from "@ngrx/store";
import {
  GetNotifications,
  GetNotificationsError,
  GetNotificationsSuccess,
  ReadNotification,
  ReadNotificationError,
  ReadNotificationSuccess, ResetAdminVendorState,
  UpdateNotifications, UpdateNotificationsError, UpdateNotificationsSuccess,
} from "./admin-vendor.actions";

export interface AdminVendorState {
  success: string;
  error: string;
  notifications: any;
  notificationSeen: any;
}

const initAdminVendorState: AdminVendorState = {
  success: '', error: '', notifications: null, notificationSeen: null
}

const adminVendorReducer = createReducer(initAdminVendorState,
  on(GetNotifications, (state) => ({...state, notifications: null, error: '', success: ''})),
  on(GetNotificationsSuccess, (state, {notifications}) => ({...state, notifications, error: '', success: ''})),
  on(GetNotificationsError, (state, {error}) => ({...state, notifications: null, error, success: ''})),

  on(UpdateNotifications, (state) => ({...state, notificationSeen: null, error: '', success: ''})),
  on(UpdateNotificationsSuccess, (state, {notificationSeen}) => ({...state, notificationSeen, error: '', success: ''})),
  on(UpdateNotificationsError, (state, {error}) => ({...state, notificationSeen: null, error, success: ''})),

  on(ReadNotification, (state) => ({...state, error: '', success: ''})),
  on(ReadNotificationSuccess, (state) => ({...state, error: '', success: '---IGNORE---'})),
  on(ReadNotificationError, (state, {error}) => ({...state, error, success: ''})),

  on(ResetAdminVendorState, (state, {params}) => ({
    ...state,
    ...params
  }))
)

export function reducer(state = initAdminVendorState, action: Action) {
  return adminVendorReducer(state, action);
}
