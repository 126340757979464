import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {DataTablesModule} from "angular-datatables";
import {RouterModule} from "@angular/router";
import {SharedModule} from "@kwot/shared";
import {CustomTableComponent} from "./components/custom-table/custom-table.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DataTablesModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [CustomTableComponent],
  exports: [CustomTableComponent]
})
export class CustomTableModule {}
