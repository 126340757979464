<div class="export position-relative">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="back-btn d-flex align-items-center" [routerLink]="['/admin', 'dashboard']">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
          <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
        </div>
        <div class="tab-content">
            <div class="export-list">
              <div class="container-fluid">
                <h2 class="text-center"> {{'export_buyer_list' | translate}}</h2>
                <div class="row mt-4">
                  <div class="dataTables_scroll" *ngIf="allOptions?.length > 0">
                    <table>
                      <thead>
                      <tr>
                        <td>
                          <p>
                            {{'name' | translate}}
                          </p>
                        </td>
                        <td *ngIf="checkData()">
                          <p>
                            {{'last_updated' | translate}}
                          </p>
                        </td>
                      </tr>
                      </thead>
                      <tbody *ngIf="allOptions?.length > 0">
                      <tr *ngFor="let subscriptionPlan of allOptions">
                        <td>
                          <p>{{subscriptionPlan.name}}</p>
                        </td>
                        <td  *ngIf="checkData()">
                          <button class="btn-action export-btn"
                                  (click)="getCSVFile(subscriptionPlan)"> {{'export' | translate}}</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
