<div class="review-info">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12d-flex justify-content-center">
        <div class="position-relative">
          <h3 class="text-center mt-2">{{'review_info' | translate}}</h3>
          <div class="close-icon-btn" id="close">
            <img class="img-fluid close-icon cursor-pointer d-md-block d-none ms-auto text-end"
                 src="/assets/images/icons/Cross.svg"
                 alt="" (click)="bsModalRef.hide()">
          </div>
        </div>
      </div>
      <div class="user-data">
        <div class="d-flex">
          <img [lazyLoad]="reviewInfo.userId.image"
               [defaultImage]="'assets/images/logo-small-round.svg'" class="product-image me-3" alt="">
          <p
            class="ms-2 mt-4 personl-info fw-bold fs-24">{{reviewInfo.userId.firstName + reviewInfo.userId.lastName ? reviewInfo.userId.firstName + reviewInfo.userId.lastName : 'Guest'}}</p>
        </div>
      </div>
      <div class="user-data mt-3" *ngIf="reviewInfo.productId">
        <h3 class="mt-2 fs-18">{{'product_info' | translate}}</h3>
        <div class="d-flex">
          <img [lazyLoad]="reviewInfo.productId?.coverImage"
               [defaultImage]="'/assets/images/loaders/skeleton_loader.gif'" class="product-image me-3 mt-1" alt="">
          <p class="ms-1 mt-4 personl-info fw-bold fs-24">{{reviewInfo.productId?.productName}}</p>

        </div>
      </div>
      <div class="user-data mt-3" *ngIf="reviewInfo.vendorId">
        <h3 class="mt-2 fs-18">{{'seller_info' | translate}}</h3>
        <div class="d-flex">
          <img [lazyLoad]="reviewInfo.vendorId?.logo"
               *ngIf="reviewInfo.vendorId?.logo"
               [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
          <span class="store-name-logo" *ngIf="!reviewInfo.vendorId?.logo">
                {{reviewInfo.vendorId?.name?.substring(0, 2) | uppercase}}</span>
          <p class="ms-1 mt-4 personl-info fw-bold fs-24">{{reviewInfo.vendorId?.name}}</p>

        </div>
      </div>
      <div>
        <!--        <h3 class="mt-4 fs-18">{{'product_review' | translate}}</h3>-->
        <h3 class="mt-4 fs-18">{{(reviewInfo.vendorId ? 'rate_review_seller' : 'rate_review_product') | translate}}</h3>
        <ul class="list-unstyled p-0 m-0 d-flex">
          <li class="me-2" *ngFor="let stars of [].constructor(reviewInfo.rating || 0)">
            <img [ngStyle]="{width: '20px', height: '20px'}" src="assets/images/icons/star-icon.svg" alt=""/>
          </li>
          <li class="me-2" *ngFor="let stars of [].constructor(5 - (reviewInfo.rating || 0))">
            <img [ngStyle]="{width: '20px', height: '20px'}" src="assets/images/icons/star-border-icon.svg"
                 alt=""/>
          </li>
        </ul>
        <div class="d-flex mt-3">
          <label class="fw-bold fs-24 product-review">{{'review' | translate}} : </label>
          <p class="ms-2 fs-22">{{reviewInfo.review}}</p>
        </div>
        <div class="d-flex">
          <div *ngFor="let image of reviewInfo.images">
            <img [lazyLoad]="image"
                 [defaultImage]="'/assets/images/loaders/skeleton_loader.gif'" class="search-icon me-3" alt="">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
