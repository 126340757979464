import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-review-info',
  templateUrl: './review-info.component.html',
  styleUrls: ['./review-info.component.scss']
})
export class ReviewInfoComponent implements OnInit {
  reviewInfo: any;
  reviewImage: any;
  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {}
}
