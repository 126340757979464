import {createAction, props} from "@ngrx/store";
import {PaginatedProducts, Policy} from "@kwot/data-models";

const VendorBuyerActions = {
  CONTACT_US_DATA: '[VENDOR_BUYER_SHARED] Add contact us data',
  CONTACT_US_DATA_SUCCESS: '[VENDOR_BUYER_SHARED] Add contact us data success',
  CONTACT_US_DATA_ERROR: '[VENDOR_BUYER_SHARED] Add contact us data error',
  GET_POLICY_DATA: '[VENDOR_BUYER_SHARED] Get policy data',
  GET_POLICY_DATA_SUCCESS: '[VENDOR_BUYER_SHARED] Get policy data success',
  GET_POLICY_DATA_ERROR: '[VENDOR_BUYER_SHARED] Get policy data error',
  GET_NEGOTIATIONS: '[VENDOR_BUYER_SHARED] Get Negotiation',
  GET_NEGOTIATIONS_SUCCESS: '[VENDOR_BUYER_SHARED] Get Negotiation Success',
  GET_NEGOTIATIONS_ERROR: '[VENDOR_BUYER_SHARED] Get Negotiation Error',
  START_NEGOTIATION: '[VENDOR_BUYER_SHARED] Start negotiation',
  START_NEGOTIATION_SUCCESS: '[VENDOR_BUYER_SHARED] Start negotiation success',
  START_NEGOTIATION_ERROR: '[VENDOR_BUYER_SHARED] Start negotiation error',
  ORDER_OFFER_LETTER: '[VENDOR_BUYER_SHARED] Order Offer Letter',
  ORDER_OFFER_LETTER_SUCCESS: '[VENDOR_BUYER_SHARED] Order Offer Letter Success',
  ORDER_OFFER_LETTER_ERROR: '[VENDOR_BUYER_SHARED] Order Offer Letter Error',
  GET_PROPOSAL_LIST: '[VENDOR_BUYER_SHARED] Get proposal vendor list data',
  GET_PROPOSAL_LIST_SUCCESS: '[VENDOR_BUYER_SHARED] Get proposal vendor list data success',
  GET_PROPOSAL_LIST_ERROR: '[VENDOR_BUYER_SHARED] Get proposal vendor list data error',
  GET_VENDOR_ALL_PRODUCTS: '[VENDOR_BUYER_SHARED] Get vendor products all',
  GET_VENDOR_ALL_PRODUCTS_SUCCESS: '[VENDOR_BUYER_SHARED] Get vendor products all success',
  GET_VENDOR_ALL_PRODUCTS_ERROR: '[VENDOR_BUYER_SHARED] Get vendor products all error',
  GET_RFQ_TERM_SHEET: '[VENDOR_BUYER_SHARED] Get Rfq term sheet',
  GET_RFQ_TERM_SHEET_SUCCESS: '[VENDOR_BUYER_SHARED] Get Rfq term sheet Success',
  GET_RFQ_TERM_SHEET_ERROR: '[VENDOR_BUYER_SHARED] Get Rfq term sheet Error',
  RESET_VENDOR_BUYER_STATE: '[VENDOR_BUYER_SHARED] Reset Vendor Buyer State'
}

export const addContactData = createAction(VendorBuyerActions.CONTACT_US_DATA, props<{ body: any }>());
export const addContactDataSuccess = createAction(VendorBuyerActions.CONTACT_US_DATA_SUCCESS);
export const addContactDataError = createAction(VendorBuyerActions.CONTACT_US_DATA_ERROR, props<{ error: string }>());

export const GetPolicyData = createAction(VendorBuyerActions.GET_POLICY_DATA, (params: any = {}) => params);
export const GetPolicyDataSuccess = createAction(VendorBuyerActions.GET_POLICY_DATA_SUCCESS, props<{ policy: Policy }>());
export const GetPolicyDataError = createAction(VendorBuyerActions.GET_POLICY_DATA_ERROR, props<{ error: string }>());

export const GetNegotiationData = createAction(VendorBuyerActions.GET_NEGOTIATIONS, (params: any = {}) => params);
export const GetNegotiationDataSuccess = createAction(VendorBuyerActions.GET_NEGOTIATIONS_SUCCESS, props<{ negotiationDetails: any }>());
export const GetNegotiationDataError = createAction(VendorBuyerActions.GET_NEGOTIATIONS_ERROR, props<{ error: string }>());

export const StartNegotiation = createAction(VendorBuyerActions.START_NEGOTIATION, (items: any = {}) => items);
export const StartNegotiationSuccess = createAction(VendorBuyerActions.START_NEGOTIATION_SUCCESS, props<{ negotiationDetails: any }>());
export const StartNegotiationError = createAction(VendorBuyerActions.START_NEGOTIATION_ERROR, props<{ error: string }>());

export const OrderOfferLetter = createAction(VendorBuyerActions.ORDER_OFFER_LETTER, props<{ orderId: string, params: any }>());
export const OrderOfferLetterSuccess = createAction(VendorBuyerActions.ORDER_OFFER_LETTER_SUCCESS, props<{ orderOfferLetter: any }>());
export const OrderOfferLetterError = createAction(VendorBuyerActions.ORDER_OFFER_LETTER, props<{ error: string }>());

export const GetVendorAllProducts = createAction(VendorBuyerActions.GET_VENDOR_ALL_PRODUCTS, (params: any = {}) => params)
export const GetVendorAllProductsSuccess = createAction(VendorBuyerActions.GET_VENDOR_ALL_PRODUCTS_SUCCESS, props<{ products: PaginatedProducts }>());
export const GetVendorAllProductsError = createAction(VendorBuyerActions.GET_VENDOR_ALL_PRODUCTS_ERROR, props<{ error: string }>());

export const GetProposalVendorList = createAction(VendorBuyerActions.GET_PROPOSAL_LIST, (params: any = {}) => params);
export const GetProposalVendorListSuccess = createAction(VendorBuyerActions.GET_PROPOSAL_LIST_SUCCESS, props<{ proposalList: any }>());
export const GetProposalVendorListError = createAction(VendorBuyerActions.GET_PROPOSAL_LIST_ERROR, props<{ error: string }>());

export const GetRFQTermSheet = createAction(VendorBuyerActions.GET_RFQ_TERM_SHEET, props<{ orderId: string, params: any }>());
export const GetRFQTermSheetSuccess = createAction(VendorBuyerActions.GET_RFQ_TERM_SHEET, props<{ rfqTermSheet: any }>());
export const GetRFQTermSheetError = createAction(VendorBuyerActions.GET_RFQ_TERM_SHEET, props<{ error: string }>());

export const ResetVendorBuyerState = createAction(VendorBuyerActions.RESET_VENDOR_BUYER_STATE, (params: any = {}) => params);
