import {Component, OnInit, ViewChild} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {Store as StoreModel} from "@kwot/data-models";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomTableComponent} from "@kwot/custom-table";
import * as dayjs from "dayjs";
import {AdminState} from "../../+state/admin.reducer";
import {GetCsvFile, GetPayoutList, UpdatePayoutStatus} from "../../+state/admin.actions";
import {getPayout} from "../../+state/admin.selectors";
import {AuthState, getLoggedInUser} from "@kwot/auth-store";

@Component({
  selector: 'kwot-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {
  currentUser: any;
  vendor: StoreModel;
  submitted = false;
  isMenuShow = false;
  unsubscriber = new Subject();
  allData: any;
  dateFilter: UntypedFormGroup;
  payoutType: 'seller' | 'user' = 'seller';
  totalMonth: any[] = [
    {
      name: 'January',
      id: '01'
    },
    {
      name: 'February',
      id: '02'
    },
    {
      name: 'March',
      id: '03'
    },
    {
      name: 'April',
      id: '04'
    },
    {
      name: 'May',
      id: '05'
    },
    {
      name: 'June',
      id: '06'
    },
    {
      name: 'July',
      id: '07'
    },
    {
      name: 'August',
      id: '08'
    },
    {
      name: 'September',
      id: '09'
    },
    {
      name: 'October',
      id: '10'
    },
    {
      name: 'November',
      id: '11'
    },
    {
      name: 'December',
      id: '12'
    },
  ];

  currMonth: any[] = []

  month: any[] = [
    {
      name: 'Current Week',
      id: `${dayjs().startOf('week').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Current Month',
      id: `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 30 days',
      id: `${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 6 month',
      id: `${dayjs().subtract(6, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 12 Months',
      id: `${dayjs().subtract(12, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
  ];
  customDatatableSettings: any = {};
  transactionDate: any = [];
  dates: any;
  selectedFilter: string | number = '';
  walletData: any;
  monthlyTransactionList: any = []
  currentPage = 1;
  itemsPerPage = 10;
  scheduleTypes = 'Weekly';
  scheduleMenu: any;
  vendorPayoutTransactionDetail: any = [];
  timeout: any = null;
  currencyRate: any;

  dayName: any[] = [
    'Sunday',
    'Monday',
    'TuesDay',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  status: any[] = [
    {
      id: "ON HOLD",
      name: "On Hold"
    },
    {
      id: "PAID",
      name: "Paid"
    }
  ]

  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;


  constructor(
    private authStore: Store<AuthState>,
    private adminStore: Store<AdminState>,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.dateFilter = this.formBuilder.group({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      monthRange: [null, [Validators.required]],
    })

    this.subscribeToStore()
  }

  ngOnInit(): void {

    this.customDatatableSettings = {
      paging: true,
      withPage: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      orderColumns: ['sellerName', 'createdAt', 'amount', 'status'],
      order: [[1, 'desc']],
      searching: false,
    }
  }

  subscribeToStore() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      })

    this.adminStore.pipe(select(getPayout))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(payout => {
        if(payout) {
          this.vendorPayoutTransactionDetail = payout;
          let groupViceTransaction: any = [];
          this.vendorPayoutTransactionDetail?.data?.forEach((item: any) => {
            const formattedDate = dayjs(item.createdAt).startOf('month').format('MMMM YYYY')
            const currentList = groupViceTransaction[formattedDate] ?? []
            Object.assign(groupViceTransaction, {[formattedDate]: [...currentList, item]})
          })

          const groupViceTransactionValue: any = Object.values(groupViceTransaction);
          const groupViceTransactionIndex: any = Object.keys(groupViceTransaction);
          this.monthlyTransactionList = [];
          for (let i = 0; i < groupViceTransactionValue.length; i++) {
            this.monthlyTransactionList.push({value: groupViceTransactionIndex[i], type: 'group'});

            for (let j = 0; j < groupViceTransactionValue[i].length; j++) {
              this.monthlyTransactionList.push(groupViceTransactionValue[i][j]);
            }
          }
          this.monthlyTransactionList = this.monthlyTransactionList.map((item: any) => ({
            ...item,
            status: item.status
          }))
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: this.vendorPayoutTransactionDetail.count.total,
              recordsFiltered: this.vendorPayoutTransactionDetail.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
  }

  filterWalletData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    this.adminStore.dispatch(GetPayoutList({
      params: {
        page: this.currentPage - 1,
        limit: this.itemsPerPage,
        monthRange: this.dateFilter.value.monthRange,
        payoutType: this.payoutType
      }
    }))
  }

  search(event: any) {
    if (event) {
      let walletSearch = event.target.value;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        walletSearch = walletSearch.trim();
        if (event.keyCode != 13 && walletSearch.length > 0) {
          this.getAllWalletTransaction(undefined, undefined, walletSearch, undefined)
        } else {
          this.getAllWalletTransaction(undefined, undefined, undefined, undefined)
        }
      }, 1500);
    }
  }

  statusData( id: any) {
    this.adminStore.dispatch(UpdatePayoutStatus({
      params: {
        id,
        status: 'PAID',
        type: this.payoutType
      }
    }))
  }

  getAllWalletTransaction = (page = 0, perPage = 10, search = '', order = {
    filterBy: 'status',
    filterType: 'dec'
  }) => {
    this.adminStore.dispatch(GetPayoutList({
      params: {
        page, limit: perPage,
        search,
        payoutType: this.payoutType,
        ...order
      }
    }))
  }

  enableChanged(event: any) {
    if (event.target.checked) {
      this.payoutType = 'user';
    } else {
      this.payoutType = 'seller'
    }
    this.adminStore.dispatch(GetPayoutList({
      params: {
        page: this.currentPage - 1,
        limit: this.itemsPerPage,
        monthRange: this.dateFilter.value.monthRange,
        payoutType: this.payoutType
      }
    }))
  }

  getCSVFile() {
    this.adminStore.dispatch(GetCsvFile({rate: this.currencyRate, type: this.payoutType}))
    this.currencyRate = '';
    this.adminStore.dispatch(UpdatePayoutStatus({params: {type: this.payoutType}}));
  }

  checkData() {
    if (this.currentUser.role !== 'MANAGEMENT' && this.currentUser.role !== 'IT') {
      return this.currentUser.permissions.some((item: any) => item.menu === 'finance' && item.permissionType.some((per: any) => per === 'write'));
    } else {
      return this.currentUser.role === 'MANAGEMENT' || this.currentUser.role === 'IT';
    }
  }
}
