import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AdminVendorState} from "./admin-vendor.reducer";

const getAdminVendorState = createFeatureSelector<AdminVendorState>('admin-vendor');

export const getNotifications = createSelector(getAdminVendorState, (state: AdminVendorState) => state.notifications);

export const getAdminVendorSuccess = createSelector(getAdminVendorState, (state: AdminVendorState) => state.success);

export const getAdminVendorError = createSelector(getAdminVendorState, (state: AdminVendorState) => state.error);
