import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AdminState} from "../../+state/admin.reducer";
import {ResetAdminState, UserCarts} from "../../+state/admin.actions";
import {getCarts} from "../../+state/admin.selectors";
import {WindowService} from "@kwot/app-config";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomModalComponent} from "../../../../../../libs/shared/src/lib/components/custom-modal/custom-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-carts-list',
  templateUrl: './carts-list.component.html',
  styleUrls: ['./carts-list.component.scss']
})
export class CartsListComponent implements OnInit, OnDestroy {

  cartsList: any[] = [];
  unsubscriber = new Subject();
  customDatatableSettings: any = {};
  isMobile: boolean;
  screenSize: number;
  queryParams: any;
  dataCount = 50000000000;
  firstTime = true;
  modalRef?: BsModalRef | null;
  showCartAttempts: any;

  constructor(
    private adminStore: Store<AdminState>,
    private windowService: WindowService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private modelService: BsModalService
  ) {
    this.adminStore.dispatch(ResetAdminState({params: {error: '', success: '', carts: null}}));
    this.activeRoute.queryParams.pipe(takeUntil(this.unsubscriber))
      .subscribe(query => {
        this.queryParams = {
          page: query.page ? (parseInt(query.page) === 0 ? 1 : parseInt(query.page)) : 1,
        };
        this.getData((this.queryParams.page - 1), query.perPage);
      })
    this.subscribeToStore();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getCarts))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(carts => {
        if (carts) {
          this.cartsList = [...carts.data];
          this.dataCount = carts.count?.total;
        }
      })
  }

  showProducts(cart: any) {
    const modelRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered confirmation',
      initialState: {
        products: cart.items,
      }
    });
    modelRef.content.showImage = false;
    modelRef.content.title = 'Cart Products';
    modelRef.content.showButtons = false;
  }

  showAttempts(template: any, cart: any) {
    this.showCartAttempts = cart;
    this.modalRef = this.modelService.show(template, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered confirmation',
    });
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      ...this.customDatatableSettings,
      paging: false,
      serverSide: true,
      searching: false,
      ordering: false,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
    }
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
  }

  getAllOrders = () => {
    if (!this.firstTime) {
      this.router.navigate(['/admin', 'carts'],
        {
          queryParamsHandling: 'merge',
          queryParams: {
            ...this.queryParams
          }
        }
      )
    }

    this.firstTime = false;
  }

  getData(page = 0, perPage = 10) {
    this.adminStore.dispatch(UserCarts({
      params: {
        page, limit: perPage
      }
    }))
  }

  changePage(event: any) {
    this.router.navigate(['/admin', 'carts'], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...this.queryParams,
        page: event.page
      }
    })
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }
}
