import {createAction, props} from "@ngrx/store";
import {
  Categories,
  Product,
  Store,
  PaginatedProducts,
  PaginatedOrders,
  Order,
  WalletDetails,
  PayoutDetails, PaginatedSubscriptionPlan, SubscriptionPlan
} from "@kwot/data-models";

const VendorActions = {
  STORE_SETUP_STORE_PREFERENCE: '[VENDOR] Store setup store preference',
  STORE_SETUP_STORE_PREFERENCE_SUCCESS: '[VENDOR] Store setup store preference success',
  STORE_SETUP_STORE_PREFERENCE_ERROR: '[VENDOR] Store setup store preference error',
  GET_CATEGORIES: '[VENDOR] Get Categories',
  GET_CATEGORIES_SUCCESS: '[VENDOR] Get Categories success',
  GET_CATEGORIES_ERROR: '[VENDOR] Get Categories error',
  GET_VENDOR_DETAILS: '[VENDOR] Get Vendor details',
  GET_VENDOR_DETAILS_SUCCESS: '[VENDOR] Get Vendor details success',
  GET_VENDOR_DETAILS_ERROR: '[VENDOR] Get Vendor details error',
  CHECK_STORE_NAME_AVAILABILITY: '[VENDOR] Check store name availability',
  CHECK_STORE_NAME_AVAILABILITY_SUCCESS: '[VENDOR] Check store name availability success',
  CHECK_STORE_NAME_AVAILABILITY_ERROR: '[VENDOR] Check store name availability error',
  STORE_SETUP_STORE_PROFILE: '[VENDOR] Store setup store profile',
  STORE_SETUP_STORE_PROFILE_SUCCESS: '[VENDOR] Store setup store profile success',
  STORE_SETUP_STORE_PROFILE_ERROR: '[VENDOR] Store setup store profile error',
  GET_VENDOR_BUSINESS_DETAILS: '[VENDOR] Get vendor business details',
  GET_VENDOR_BUSINESS_DETAILS_SUCCESS: '[VENDOR] Get vendor business details success',
  GET_VENDOR_BUSINESS_DETAILS_ERROR: '[VENDOR] Get vendor business details error',
  GET_VENDOR_BUSINESS_DETAILS_FOR_SUBSCRIPTION: '[VENDOR] Get vendor business details for subscription',
  GET_VENDOR_BUSINESS_DETAILS_FOR_SUBSCRIPTION_SUCCESS: '[VENDOR] Get vendor business details for subscription success',
  GET_VENDOR_BUSINESS_DETAILS_FOR_SUBSCRIPTION_ERROR: '[VENDOR] Get vendor business details for subscription error',
  GET_VENDOR_BANK_DETAILS: '[VENDOR] Get vendor bank details',
  GET_VENDOR_BANK_DETAILS_SUCCESS: '[VENDOR] Get vendor bank details success',
  GET_VENDOR_BANK_DETAILS_ERROR: '[VENDOR] Get vendor bank details error',
  GET_VENDOR_ALL_PRODUCTS: '[VENDOR] Get vendor products all',
  GET_VENDOR_ALL_PRODUCTS_SUCCESS: '[VENDOR] Get vendor products all success',
  GET_VENDOR_ALL_PRODUCTS_ERROR: '[VENDOR] Get vendor products all error',
  DELETE_VENDOR_PRODUCT: '[VENDOR] Delete vendor product',
  DELETE_VENDOR_PRODUCT_SUCCESS: '[VENDOR] Delete vendor product success',
  DELETE_VENDOR_PRODUCT_ERROR: '[VENDOR] Delete vendor product error',
  GET_VENDOR_PRODUCT: '[VENDOR] Get vendor product',
  GET_VENDOR_PRODUCT_SUCCESS: '[VENDOR] Get vendor product success',
  GET_VENDOR_PRODUCT_ERROR: '[VENDOR] Get vendor product error',
  RESEND_PROFILE_VERIFICATION: '[VENDOR] Resend Profile verification',
  RESEND_PROFILE_VERIFICATION_SUCCESS: '[VENDOR] Resend Profile verification success',
  RESEND_PROFILE_VERIFICATION_ERROR: '[VENDOR] Resend Profile verification error',
  UPDATE_VENDOR_PRODUCT: '[VENDOR] Update Vendor product',
  UPDATE_VENDOR_PRODUCT_SUCCESS: '[VENDOR] Update Vendor product success',
  UPDATE_VENDOR_PRODUCT_ERROR: '[VENDOR] Update Vendor product error',
  ORDERS_LIST: '[VENDOR] Orders List',
  ORDERS_LIST_SUCCESS: '[VENDOR] Orders List success',
  ORDERS_LIST_ERROR: '[VENDOR] Orders List error',
  VENDOR_DASHBOARD: '[VENDOR] Vendor Dashboard',
  VENDOR_DASHBOARD_SUCCESS: '[VENDOR] Vendor Dashboard success',
  VENDOR_DASHBOARD_ERROR: '[VENDOR] Vendor Dashboard error',
  VENDOR_ORDER_MANAGE: '[VENDOR] Vendor Orders Manage',
  VENDOR_ORDER_MANAGE_SUCCESS: '[VENDOR] Vendor Orders Manage Success',
  VENDOR_ORDER_MANAGE_ERROR: '[VENDOR] Vendor Orders Manage Error',
  GET_PRODUCT_REVIEWS: '[VENDOR] Get Product Review',
  GET_PRODUCT_REVIEWS_SUCCESS: '[VENDOR] Get Product Review Success',
  GET_PRODUCT_REVIEWS_ERROR: '[VENDOR] Get Product Review Error',
  GET_MY_REVIEWS: '[VENDOR] Get My Review',
  GET_MY_REVIEWS_SUCCESS: '[VENDOR] Get My Review Success',
  GET_MY_REVIEWS_ERROR: '[VENDOR] Get My Review Error',
  GET_WALLET_BALANCE: '[VENDOR] Get Wallet Balance',
  GET_WALLET_BALANCE_SUCCESS: '[VENDOR] Get Wallet Balance Success',
  GET_WALLET_BALANCE_ERROR: '[VENDOR] Get Wallet Balance Error',
  GET_WALLET_TRANSACTION_BALANCE: '[VENDOR] Get Wallet Transaction Balance',
  GET_WALLET_TRANSACTION_BALANCE_SUCCESS: '[VENDOR] Get Wallet Transaction Balance Success',
  GET_WALLET_TRANSACTION_BALANCE_ERROR: '[VENDOR] Get Wallet Transaction Balance Error',
  ADD_SCHEDULE_DATA: "[VENDOR] Add Schedule Data",
  ADD_SCHEDULE_DATA_SUCCESS: "[VENDOR] Add Schedule Data Success",
  ADD_SCHEDULE_DATA_ERROR: "[VENDOR] Add Schedule Data Error",
  ADD_PAYOUT_DATA: "[VENDOR] Add Payout Data",
  ADD_PAYOUT_DATA_SUCCESS: "[VENDOR] Add Payout Data Success",
  ADD_PAYOUT_DATA_ERROR: "[VENDOR] Add Payout Data Error",
  GET_PAYOUT_DETAIL: "[VENDOR] Get Payout Detail",
  GET_PAYOUT_DETAIL_SUCCESS: "[VENDOR] Get Payout Detail Success",
  GET_PAYOUT_DETAIL_ERROR: "[VENDOR] Get Payout Detail Error",
  GET_POLICY_DETAIL: "[VENDOR] Get Policy Detail",
  GET_POLICY_DETAIL_SUCCESS: "[VENDOR] Get Policy Detail Success",
  GET_POLICY_DETAIL_ERROR: "[VENDOR] Get Policy Detail Error",
  ACCEPT_POLICY_DETAIL: "[VENDOR] Accept Policy Detail",
  ACCEPT_POLICY_DETAIL_SUCCESS: "[VENDOR] Accept Policy Detail Success",
  ACCEPT_POLICY_DETAIL_ERROR: "[VENDOR] Accept Policy Detail Error",
  GET_VENDOR_ADS_DETAILS: "[VENDOR] Get vendor ads details",
  GET_VENDOR_ADS_DETAILS_SUCCESS: "[VENDOR] Get vendor ads details Success",
  GET_VENDOR_ADS_DETAILS_ERROR: "[VENDOR] Get vendor ads details Error",
  GET_CURRENCY_DATA: "[VENDOR] Get currency data",
  GET_CURRENCY_DATA_SUCCESS: "[VENDOR] Get currency data Success",
  GET_CURRENCY_DATA_ERROR: "[VENDOR] Get currency data Error",
  ADD_UPDATE_ADS: "[VENDOR] Add update ads",
  ADD_UPDATE_ADS_SUCCESS: "[VENDOR] Add update ads Success",
  ADD_UPDATE_ADS_ERROR: "[VENDOR] Add update ads Error",
  ADD_UPDATE_PAYMENT: "[VENDOR] Add update payment",
  ADD_UPDATE_PAYMENT_SUCCESS: "[VENDOR] Add update payment Success",
  ADD_UPDATE_PAYMENT_ERROR: "[VENDOR] Add update payment Error",
  GET_ADS_WALLET_TRANSACTION_BALANCE: '[VENDOR] Get Ads Wallet Transaction Balance',
  GET_ADS_WALLET_TRANSACTION_BALANCE_SUCCESS: '[VENDOR] Get Ads Wallet Transaction Balance Success',
  GET_ADS_WALLET_TRANSACTION_BALANCE_ERROR: '[VENDOR] Get Ads Wallet Transaction Balance Error',
  SUBSCRIPTION_PLAN_LIST: '[VENDOR] Subscription Plan List',
  SUBSCRIPTION_PLAN_LIST_SUCCESS: '[VENDOR] Subscription Plan List Success',
  SUBSCRIPTION_PLAN_LIST_ERROR: '[VENDOR] Subscription Plan List Error',
  GET_ACTIVE_SUBSCRIPTION_PLAN: '[VENDOR] Get Active Subscribed Plan ',
  GET_ACTIVE_SUBSCRIPTION_PLAN_SUCCESS: '[VENDOR] Get Active Subscribed Plan Success',
  GET_ACTIVE_SUBSCRIPTION_PLAN_ERROR: '[VENDOR] Get Active Subscribed Plan Error',
  GET_SUBSCRIPTION_TRANSACTION: '[VENDOR] Get Subscription Transaction',
  GET_SUBSCRIPTION_TRANSACTION_SUCCESS: '[VENDOR] GGet Subscription Transaction Success',
  GET_SUBSCRIPTION_TRANSACTION_ERROR: '[VENDOR] Get Subscription Transaction Error',
  CANCEL_SUBSCRIBED_PLAN: '[VENDOR] Cancel Subscribed Plan',
  CANCEL_SUBSCRIBED_PLAN_SUCCESS: '[VENDOR] Cancel Subscribed Plan Success',
  CANCEL_SUBSCRIBED_PLAN_ERROR: '[VENDOR] Cancel Subscribed Plan Error',
  FLUTTERWAVE_TRANSACTION_COMPLETE: '[VENDOR] Flutterwave Transaction Complete',
  FLUTTERWAVE_TRANSACTION_COMPLETE_SUCCESS: '[VENDOR] Flutterwave Transaction Complete Success',
  FLUTTERWAVE_TRANSACTION_COMPLETE_ERROR: '[VENDOR] Flutterwave Transaction Complete Error',
  GET_BANK_AND_BILLING_DETAIL: '[VENDOR] Get vendor billing and bank detail',
  GET_BANK_AND_BILLING_DETAIL_SUCCESS: '[VENDOR] Get vendor billing and bank detail Success',
  GET_BANK_AND_BILLING_DETAIL_ERROR: '[VENDOR] Get vendor billing and bank detail Error',
  ADD_BILLING_DETAILS: '[VENDOR] Add billing details',
  ADD_BILLING_DETAILS_SUCCESS: '[VENDOR] Add billing details success',
  ADD_BILLING_DETAILS_ERROR: '[VENDOR] Add billing details error',
  GET_BUYER_REVIEW_COUNT: '[VENDOR] Get buyer and store review detail',
  GET_BUYER_REVIEW_COUNT_SUCCESS: '[VENDOR] Get buyer and store review detail Success',
  GET_BUYER_REVIEW_COUNT_ERROR: '[VENDOR] Get buyer and store review detail Error',
  GET_NEGOTIATIONS: '[VENDOR] Get Negotiation List',
  GET_NEGOTIATIONS_SUCCESS: '[VENDOR] Get Negotiation List success',
  GET_NEGOTIATIONS_ERROR: '[VENDOR] Get Negotiation List error',
  GET_PROPOSALS: '[VENDOR] Get Proposals List',
  GET_PROPOSALS_SUCCESS: '[VENDOR] Get Proposals List success',
  GET_PROPOSALS_ERROR: '[VENDOR] Get Proposals List error',
  UPDATE_PROPOSAL: '[VENDOR] Update Proposal',
  UPDATE_PROPOSAL_SUCCESS: '[VENDOR] Update Proposal success',
  UPDATE_PROPOSAL_ERROR: '[VENDOR] Update Proposal error',
  SEND_REFERRAL_CODE_VERIFICATION: '[VENDOR] Send Referral code',
  SEND_REFERRAL_CODE_VERIFICATION_SUCCESS: '[VENDOR] Send Referral code success',
  SEND_REFERRAL_CODE_VERIFICATION_ERROR: '[VENDOR] Send Referral code error',
  GET_PAYMENT_INTENT: "[VENDOR] Get Payment Intent",
  GET_PAYMENT_INTENT_SUCCESS: "[VENDOR] Get Payment Intent Success",
  GET_PAYMENT_INTENT_ERROR: "[VENDOR] Get Payment Intent Error",

  GENERATE_DESCRIPTION: '[VENDOR] Generate Description',
  GENERATE_DESCRIPTION_SUCCESS: '[VENDOR] Generate Description success',
  GENERATE_DESCRIPTION_ERROR: '[VENDOR] Generate Description error',

  RESET_VENDOR_STATE: '[VENDOR] Reset Vendor State',
}

export const StoreSetupStorePreference = createAction(VendorActions.STORE_SETUP_STORE_PREFERENCE, props<{ body: Store | any, query?: any }>())
export const StoreSetupStorePreferenceSuccess = createAction(VendorActions.STORE_SETUP_STORE_PREFERENCE_SUCCESS, props<{ updatedVendor: Store }>());
export const StoreSetupStorePreferenceError = createAction(VendorActions.STORE_SETUP_STORE_PREFERENCE_ERROR, props<{ error: string }>());

export const GetCategories = createAction(VendorActions.GET_CATEGORIES, (params: any = {}) => params)
export const GetCategoriesSuccess = createAction(VendorActions.GET_CATEGORIES_SUCCESS, props<{ categories: Categories[] }>());
export const GetCategoriesError = createAction(VendorActions.GET_CATEGORIES_ERROR, props<{ error: string }>());

export const GetVendorDetails = createAction(VendorActions.GET_VENDOR_DETAILS, props<{ id: string }>())
export const GetVendorDetailsSuccess = createAction(VendorActions.GET_VENDOR_DETAILS_SUCCESS, props<{ vendor: Store }>());
export const GetVendorDetailsError = createAction(VendorActions.GET_VENDOR_DETAILS_ERROR, props<{ error: string }>());

export const CheckStoreNameAvailability = createAction(VendorActions.CHECK_STORE_NAME_AVAILABILITY, props<{ name: string }>())
export const CheckStoreNameAvailabilitySuccess = createAction(VendorActions.CHECK_STORE_NAME_AVAILABILITY_SUCCESS, props<{ isStoreNameAvailable: string }>());
export const CheckStoreNameAvailabilityError = createAction(VendorActions.CHECK_STORE_NAME_AVAILABILITY_ERROR, props<{ error: string, isStoreNameAvailable: string }>());

export const StoreSetupStoreProfile = createAction(VendorActions.STORE_SETUP_STORE_PROFILE, props<{ body: Store | any, query?: any }>())
export const StoreSetupStoreProfileSuccess = createAction(VendorActions.STORE_SETUP_STORE_PROFILE_SUCCESS, props<{ updatedVendor: Store }>());
export const StoreSetupStoreProfileError = createAction(VendorActions.STORE_SETUP_STORE_PROFILE_ERROR, props<{ error: string }>());

export const GetVendorBusinessDetails = createAction(VendorActions.GET_VENDOR_BUSINESS_DETAILS, (params: any = {}) => params)
export const GetVendorBusinessDetailsSuccess = createAction(VendorActions.GET_VENDOR_BUSINESS_DETAILS_SUCCESS, props<{ updatedVendor: Store }>());
export const GetVendorBusinessDetailsError = createAction(VendorActions.GET_VENDOR_BUSINESS_DETAILS_ERROR, props<{ error: string }>());

export const GetVendorBusinessDetailsForSubscription = createAction(VendorActions.GET_VENDOR_BUSINESS_DETAILS_FOR_SUBSCRIPTION, (params: any = {}) => params)
export const GetVendorBusinessDetailsForSubscriptionSuccess = createAction(VendorActions.GET_VENDOR_BUSINESS_DETAILS_FOR_SUBSCRIPTION_SUCCESS, props<{ updatedVendor: Store }>());
export const GetVendorBusinessDetailsForSubscriptionError = createAction(VendorActions.GET_VENDOR_BUSINESS_DETAILS_FOR_SUBSCRIPTION_ERROR, props<{ error: string }>());

export const GetVendorBankDetails = createAction(VendorActions.GET_VENDOR_BANK_DETAILS)
export const GetVendorBankDetailsSuccess = createAction(VendorActions.GET_VENDOR_BANK_DETAILS_SUCCESS, props<{ updatedVendor: Store }>());
export const GetVendorBankDetailsError = createAction(VendorActions.GET_VENDOR_BANK_DETAILS_ERROR, props<{ error: string }>());

export const GetVendorAllProducts = createAction(VendorActions.GET_VENDOR_ALL_PRODUCTS, (params: any = {}) => params)
export const GetVendorAllProductsSuccess = createAction(VendorActions.GET_VENDOR_ALL_PRODUCTS_SUCCESS, props<{ products: PaginatedProducts }>());
export const GetVendorAllProductsError = createAction(VendorActions.GET_VENDOR_ALL_PRODUCTS_ERROR, props<{ error: string }>());

export const DeleteVendorProduct = createAction(VendorActions.DELETE_VENDOR_PRODUCT, props<{ id: string }>());
export const DeleteVendorProductSuccess = createAction(VendorActions.DELETE_VENDOR_PRODUCT_SUCCESS, props<{ id: string }>());
export const DeleteVendorProductError = createAction(VendorActions.DELETE_VENDOR_PRODUCT_ERROR, props<{ error: string }>());

export const GetVendorProduct = createAction(VendorActions.GET_VENDOR_PRODUCT, props<{ id: string }>());
export const GetVendorProductSuccess = createAction(VendorActions.GET_VENDOR_PRODUCT_SUCCESS, props<{ product: Product }>());
export const GetVendorProductError = createAction(VendorActions.GET_VENDOR_PRODUCT_ERROR, props<{ error: string }>());

export const ResendProfileVerification = createAction(VendorActions.RESEND_PROFILE_VERIFICATION, (params: any = {}) => params);
export const ResendProfileVerificationSuccess = createAction(VendorActions.RESEND_PROFILE_VERIFICATION_SUCCESS);
export const ResendProfileVerificationError = createAction(VendorActions.RESEND_PROFILE_VERIFICATION_ERROR, props<{ error: string }>());

export const UpdateVendorProduct = createAction(VendorActions.UPDATE_VENDOR_PRODUCT, props<{ product: Product }>());
export const UpdateVendorProductSuccess = createAction(VendorActions.UPDATE_VENDOR_PRODUCT_SUCCESS, props<{ product: Product }>());
export const UpdateVendorProductError = createAction(VendorActions.UPDATE_VENDOR_PRODUCT_ERROR, props<{ error: string }>());

export const VendorOrdersList = createAction(VendorActions.ORDERS_LIST, (params: any = {}) => params)
export const VendorOrdersListSuccess = createAction(VendorActions.ORDERS_LIST_SUCCESS, props<{ orders: PaginatedOrders }>());
export const VendorOrdersListError = createAction(VendorActions.ORDERS_LIST_ERROR, props<{ error: string }>());

export const VendorDashboard = createAction(VendorActions.VENDOR_DASHBOARD, (params: any = {}) => params)
export const VendorDashboardSuccess = createAction(VendorActions.VENDOR_DASHBOARD_SUCCESS, props<{ dashboard: any }>());
export const VendorDashboardError = createAction(VendorActions.VENDOR_DASHBOARD_ERROR, props<{ error: string }>());

export const VendorOrderManage = createAction(VendorActions.VENDOR_ORDER_MANAGE, props<{ body: any }>());
export const VendorOrderManageSuccess = createAction(VendorActions.VENDOR_ORDER_MANAGE_SUCCESS, props<{ orderInfo: Order }>());
export const VendorOrderManageError = createAction(VendorActions.VENDOR_ORDER_MANAGE_ERROR, props<{ error: string }>());

export const GetProductReviews = createAction(VendorActions.GET_PRODUCT_REVIEWS, (params: any = {}) => params);
export const GetProductReviewsSuccess = createAction(VendorActions.GET_PRODUCT_REVIEWS_SUCCESS, props<{ reviews: any }>());
export const GetProductReviewsError = createAction(VendorActions.GET_PRODUCT_REVIEWS_ERROR, props<{ error: string }>());

export const GetMyReviews = createAction(VendorActions.GET_MY_REVIEWS, (params: any = {}) => params);
export const GetMyReviewsSuccess = createAction(VendorActions.GET_MY_REVIEWS_SUCCESS, props<{ reviews: any }>());
export const GetMyReviewsError = createAction(VendorActions.GET_MY_REVIEWS_ERROR, props<{ error: string }>());

export const GetWalletBalance = createAction(VendorActions.GET_WALLET_BALANCE, (params: any = {}) => params);
export const GetWalletBalanceSuccess = createAction(VendorActions.GET_WALLET_BALANCE_SUCCESS, props<{ wallet: WalletDetails }>());
export const GetWalletBalanceError = createAction(VendorActions.GET_WALLET_BALANCE_ERROR, props<{ error: string }>());

export const GetWalletTransaction = createAction(VendorActions.GET_WALLET_TRANSACTION_BALANCE, (params: any = {}) => params);
export const GetWalletTransactionSuccess = createAction(VendorActions.GET_WALLET_TRANSACTION_BALANCE_SUCCESS, props<{ walletTransaction: Store }>());
export const GetWalletTransactionError = createAction(VendorActions.GET_WALLET_TRANSACTION_BALANCE_ERROR, props<{ error: string }>());

export const AddScheduleData = createAction(VendorActions.ADD_SCHEDULE_DATA, props<{ body: any }>());
export const AddScheduleDataSuccess = createAction(VendorActions.ADD_SCHEDULE_DATA_SUCCESS, props<{ wallet: WalletDetails }>());
export const AddScheduleDataError = createAction(VendorActions.ADD_SCHEDULE_DATA_ERROR, props<{ error: string }>());

export const AddPayoutData = createAction(VendorActions.ADD_PAYOUT_DATA, props<{ body: any }>());
export const AddPayoutDataSuccess = createAction(VendorActions.ADD_PAYOUT_DATA_SUCCESS, props<{ payout: PayoutDetails[] }>());
export const AddPayoutDataError = createAction(VendorActions.ADD_PAYOUT_DATA_ERROR, props<{ error: string }>());

export const GetPayoutDetail = createAction(VendorActions.GET_PAYOUT_DETAIL);
export const GetPayoutDetailSuccess = createAction(VendorActions.GET_PAYOUT_DETAIL_SUCCESS, props<{ payout: PayoutDetails[] }>());
export const GetPayoutDetailError = createAction(VendorActions.GET_PAYOUT_DETAIL_ERROR, props<{ error: string }>());

export const GetPolicyDetail = createAction(VendorActions.GET_POLICY_DETAIL, (params: any = {}) => params);
export const GetPolicyDetailSuccess = createAction(VendorActions.GET_POLICY_DETAIL_SUCCESS, props<{ policy: any }>());
export const GetPolicyDetailError = createAction(VendorActions.GET_POLICY_DETAIL_ERROR, props<{ error: string }>());

export const AcceptPolicyDetail = createAction(VendorActions.ACCEPT_POLICY_DETAIL, (params: any = {}) => params);
export const AcceptPolicyDetailSuccess = createAction(VendorActions.ACCEPT_POLICY_DETAIL_SUCCESS);
export const AcceptPolicyDetailError = createAction(VendorActions.ACCEPT_POLICY_DETAIL_ERROR, props<{ error: string }>());

export const GetVendorAdsDetails = createAction(VendorActions.GET_VENDOR_ADS_DETAILS);
export const GetVendorAdsDetailsSuccess = createAction(VendorActions.GET_VENDOR_ADS_DETAILS_SUCCESS, props<{ adsDetails: any }>());
export const GetVendorAdsDetailsError = createAction(VendorActions.GET_VENDOR_ADS_DETAILS_ERROR, props<{ error: string }>());

export const GetCurrencyData = createAction(VendorActions.GET_CURRENCY_DATA);
export const GetCurrencyDataSuccess = createAction(VendorActions.GET_CURRENCY_DATA_SUCCESS, props<{ currencyData: any }>());
export const GetCurrencyDataError = createAction(VendorActions.GET_CURRENCY_DATA_ERROR, props<{ error: string }>());

export const AddUpdateAds = createAction(VendorActions.ADD_UPDATE_ADS, props<{ body: any }>());
export const AddUpdateAdsSuccess = createAction(VendorActions.ADD_UPDATE_ADS_SUCCESS, props<{ adsDetails: any }>());
export const AddUpdateAdsError = createAction(VendorActions.ADD_UPDATE_ADS_ERROR, props<{ error: string }>());

export const AddAdsPaymentDetails = createAction(VendorActions.ADD_UPDATE_PAYMENT, props<{ body: any }>());
export const AddAdsPaymentDetailsSuccess = createAction(VendorActions.ADD_UPDATE_PAYMENT_SUCCESS);
export const AddAdsPaymentDetailsError = createAction(VendorActions.ADD_UPDATE_PAYMENT_ERROR, props<{ error: string }>());

export const GetAdsWalletTransaction = createAction(VendorActions.GET_ADS_WALLET_TRANSACTION_BALANCE, (params: any = {}) => params);
export const GetAdsWalletTransactionSuccess = createAction(VendorActions.GET_ADS_WALLET_TRANSACTION_BALANCE_SUCCESS, props<{ adsWalletTransaction: Store }>());
export const GetAdsWalletTransactionError = createAction(VendorActions.GET_ADS_WALLET_TRANSACTION_BALANCE_ERROR, props<{ error: string }>());

export const SubscriptionPlanList = createAction(VendorActions.SUBSCRIPTION_PLAN_LIST, (params: any = {}) => params);
export const SubscriptionPlanListSuccess = createAction(VendorActions.SUBSCRIPTION_PLAN_LIST_SUCCESS, props<{ subscriptionPlanList: PaginatedSubscriptionPlan }>());
export const SubscriptionPlanListError = createAction(VendorActions.SUBSCRIPTION_PLAN_LIST_ERROR, props<{ error: string }>());

export const GetActiveSubscribedPlan = createAction(VendorActions.GET_ACTIVE_SUBSCRIPTION_PLAN);
export const GetActiveSubscribedPlanSuccess = createAction(VendorActions.GET_ACTIVE_SUBSCRIPTION_PLAN_SUCCESS, props<{ activeSubscribedPlan: any }>());
export const GetActiveSubscribedPlanError = createAction(VendorActions.GET_ACTIVE_SUBSCRIPTION_PLAN_ERROR, props<{ error: string }>());

export const GetSubscriptionTransaction = createAction(VendorActions.GET_SUBSCRIPTION_TRANSACTION, (params: any = {}) => params);
export const GetSubscriptionTransactionSuccess = createAction(VendorActions.GET_ACTIVE_SUBSCRIPTION_PLAN_SUCCESS, props<{ subscriptionTransaction: Store }>());
export const GetSubscriptionTransactionError = createAction(VendorActions.GET_SUBSCRIPTION_TRANSACTION_ERROR, props<{ error: string }>());

export const CancelSubscribedPlan = createAction(VendorActions.CANCEL_SUBSCRIBED_PLAN, props<{ body: any }>());
export const CancelSubscribedPlanSuccess = createAction(VendorActions.CANCEL_SUBSCRIBED_PLAN_SUCCESS, props<{ subscribedPlan: SubscriptionPlan }>());
export const CancelSubscribedPlanError = createAction(VendorActions.CANCEL_SUBSCRIBED_PLAN_ERROR, props<{ error: string }>());

export const FlutterwaveConfirmTransaction = createAction(VendorActions.FLUTTERWAVE_TRANSACTION_COMPLETE, props<{ body: any }>());
export const FlutterwaveConfirmTransactionSuccess = createAction(VendorActions.FLUTTERWAVE_TRANSACTION_COMPLETE_SUCCESS, props<{ subscribedPlan: SubscriptionPlan }>());
export const FlutterwaveConfirmTransactionError = createAction(VendorActions.FLUTTERWAVE_TRANSACTION_COMPLETE_ERROR, props<{ error: string }>());

export const GetBankAndBilligDetail = createAction(VendorActions.GET_BANK_AND_BILLING_DETAIL, (params: any = {}) => params);
export const GetBankAndBilligDetailSuccess = createAction(VendorActions.GET_BANK_AND_BILLING_DETAIL_SUCCESS, props<{ bankAndBillingData: any }>());
export const GetBankAndBilligDetailError = createAction(VendorActions.GET_BANK_AND_BILLING_DETAIL_ERROR, props<{ error: string }>());

export const AddBillingDetails = createAction(VendorActions.ADD_BILLING_DETAILS, props<{ body: any }>());
export const AddBillingDetailsSuccess = createAction(VendorActions.ADD_BILLING_DETAILS_SUCCESS);
export const AddBillingDetailsError = createAction(VendorActions.ADD_BILLING_DETAILS_ERROR, props<{ error: string }>());

export const GetBuyerAndReviewCount = createAction(VendorActions.GET_BUYER_REVIEW_COUNT);
export const GetBuyerAndReviewCountSuccess = createAction(VendorActions.GET_BUYER_REVIEW_COUNT_SUCCESS, props<{ reviewCount: any }>());
export const GetBuyerAndReviewCountError = createAction(VendorActions.GET_BUYER_REVIEW_COUNT_ERROR, props<{ error: string }>());

export const GetNegotiations = createAction(VendorActions.GET_NEGOTIATIONS, (params: any = {}) => params);
export const GetNegotiationsSuccess = createAction(VendorActions.GET_NEGOTIATIONS_SUCCESS, props<{ negotiations: any }>());
export const GetNegotiationsError = createAction(VendorActions.GET_NEGOTIATIONS_ERROR, props<{ error: string }>());

export const GetProposals = createAction(VendorActions.GET_PROPOSALS, (params: any = {}) => params);
export const GetProposalsSuccess = createAction(VendorActions.GET_PROPOSALS_SUCCESS, props<{ proposals: any }>());
export const GetProposalsError = createAction(VendorActions.GET_PROPOSALS_ERROR, props<{ error: string }>());

export const UpdateProposal = createAction(VendorActions.UPDATE_PROPOSAL, props<{ body: any }>());
export const UpdateProposalSuccess = createAction(VendorActions.UPDATE_PROPOSAL_SUCCESS, props<{ proposal: any }>());
export const UpdateProposalError = createAction(VendorActions.UPDATE_PROPOSAL_ERROR, props<{ error: string }>());

export const SendReferralCode = createAction(VendorActions.SEND_REFERRAL_CODE_VERIFICATION, (params: any = {}) => params);
export const SendReferralCodeSuccess = createAction(VendorActions.SEND_REFERRAL_CODE_VERIFICATION_SUCCESS);
export const SendReferralCodeError = createAction(VendorActions.SEND_REFERRAL_CODE_VERIFICATION_ERROR, props<{ error: string }>());

export const GetIntent = createAction(VendorActions.GET_PAYMENT_INTENT, (items: any = {}) => items);
export const GetIntentSuccess = createAction(VendorActions.GET_PAYMENT_INTENT_SUCCESS, props<{ paymentIntent: any }>());
export const GetIntentError = createAction(VendorActions.GET_PAYMENT_INTENT_ERROR, props<{ error: string }>());

export const GenerateDescription = createAction(VendorActions.GENERATE_DESCRIPTION, props<{ body: any }>());
export const GenerateDescriptionSuccess = createAction(VendorActions.GENERATE_DESCRIPTION_SUCCESS, props<{ description: string }>());
export const GenerateDescriptionError = createAction(VendorActions.GENERATE_DESCRIPTION_ERROR, props<{ error: string }>());

export const ResetVendorState = createAction(VendorActions.RESET_VENDOR_STATE, (params: any = {}) => params);


