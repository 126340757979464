import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_CONFIG} from '@kwot/app-config';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpAuthInterceptService} from '@kwot/app-config';
import {NoAuthGuard} from './guards/no-auth.guard';
import {MenuComponent} from './components/menu/menu.component';
import {SharedModule} from '@kwot/shared';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SellerDeactivateComponent} from './_components/seller-deactivate/seller-deactivate.component';
import {OrdersListComponent} from './components/orders-list/orders-list.component';
import {AuthGuard} from './guards/auth.guard';
import * as fromAdmin from './+state/admin.reducer';
import {AdminEffects} from './+state/admin.effects';
import {FinanceComponent} from './components/finance/finance.component';
import {ReviewsComponent} from './components/reviews/reviews.component';
import {ReviewInfoComponent} from './components/review-info/review-info.component';
import {AdminVendorSharedModule} from "@kwot/admin-vendor-shared";
import {SelectModule} from "@kwot/select";
import {PopoverModule} from "ngx-bootstrap/popover";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {BsModalService} from "ngx-bootstrap/modal";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {CustomTableModule} from "@kwot/custom-table";
import {ExportComponent} from "./components/export/export.component";
import {CartsListComponent} from "./components/carts-list/carts-list.component";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {RfqNegotiationComponent} from "../../../../libs/vendor-buyer-shared/src/lib/components/rfq-negotiation/rfq-negotiation.component";
import {RfqsListComponent} from "./components/rfqs-list/rfqs-list.component";

const routes: Routes = [
  {path: '', redirectTo: 'admin/dashboard', pathMatch: 'full'},
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@kwot/auth').then((module) => {
            module.AuthModule.environment = environment;
            return module.AuthModule;
          }),
        canActivate: [NoAuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((module) => module.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'buyers',
        loadChildren: () => import('./modules/buyer/buyer.module').then((module) => module.BuyerModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'sellers',
        loadChildren: () => import('./modules/seller/seller.module').then((module) => module.SellerModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'orders',
        component: OrdersListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'finance',
        component: FinanceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'export',
        component: ExportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./modules/products/products.module').then((module) => module.ProductsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'orders',
        loadChildren: () => import('@kwot/orders').then((module) => module.OrdersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'categories',
        loadChildren: () => import('./modules/categories/categories.module').then((module) => module.CategoriesModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('@kwot/vendor-admin-profile').then((module) => module.UserProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'site-settings',
        loadChildren: () => import('./modules/site-settings/site-settings.module').then((module) => module.SiteSettingsModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./modules/audits/audits.module').then((module) => module.AuditsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'currency-rate',
        loadChildren: () => import('./modules/currency/currency.module').then((module) => module.CurrencyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'shipping-cost',
        loadChildren: () => import('./modules/shipping/shipping.module').then((module) => module.ShippingModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./modules/policy/policy.module').then((module) => module.PolicyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'ads',
        loadChildren: () =>
          import('@kwot/ads').then((module) => module.AdsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'promo-code',
        loadChildren: () =>
          import('@kwot/promo-code').then((module) => module.PromoCodeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'gift',
        loadChildren: () =>
          import('./modules/gift/gift-card.module').then(
            (module) => module.GiftCardModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('@kwot/messages').then((module) => module.MessagesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'email-templates',
        loadChildren: () =>
          import('./modules/email-template/email-templates.module').then(
            (module) => module.EmailTemplatesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'affiliate-users',
        loadChildren: () =>
          import('./modules/affiliate-users/affiliate-users.module').then(
            (module) => module.AffiliateUsersModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'subscription-plans',
        loadChildren: () => import('./modules/subscription-plan/subscription-plan.module').then((module) => module.SubscriptionPlanModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'crons',
        loadChildren: () => import('./modules/cron/crons.module').then((module) => module.CronsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'staff',
        loadChildren: () => import('./modules/staff/staff.module').then((module) => module.StaffModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'carts',
        component: CartsListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'rfqs',
        component: RfqsListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'rfq-negotiation/:id',
        component: RfqNegotiationComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SellerDeactivateComponent,
    OrdersListComponent,
    FinanceComponent,
    ReviewsComponent,
    ReviewInfoComponent,
    ExportComponent, CartsListComponent, RfqsListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AdminVendorSharedModule,
    RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    HttpClientModule,
    StoreModule.forRoot({admin: fromAdmin.reducer}, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([AdminEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ReactiveFormsModule,
    FormsModule,
    SelectModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    CustomTableModule,
    NgxJsonViewerModule
  ],
  providers: [
    {provide: APP_CONFIG, useValue: environment},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true,
    },
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
