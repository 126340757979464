import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {AdminState} from "../../+state/admin.reducer";
import {GetUserCsvFile} from "../../+state/admin.actions";
import {AuthState, getLoggedInUser} from "@kwot/auth-store";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'kwot-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, OnDestroy {
  allOptions: any[] = [
    {
      name: 'Buyers who have not visited platform',
      id: 'notVisitedPlatform',
      csvName: 'No-Platform-visited'
    },
    // {
    //   name: 'Abandoned Cart',
    //   id: 'abandonedCart'
    // },
    {
      name: 'WishList',
      id: 'wishList',
      csvName: 'WishList'
    },
    {
      name: 'Buyers who have not made any purchases',
      id: 'notPurchases',
      csvName: 'No-purchase'
    },
    {
      name: 'No GiftCard Redeem',
      id: 'notRedeemedGiftCard',
      csvName: 'No-GiftCard-Redeemed'
    },
    {
      name: 'New Registered users',
      id: 'newSignUps',
      csvName: 'New-Registered-Users'
    },
    {
      name: 'Over Purchased Period',
      id: 'overPurchasedPeriod',
      csvName: 'Over-Purchase-Period'
    },
  ];
  selectedOption: any;
  currentUser: any;
  unsubscriber = new Subject();

  constructor(
    private adminStore: Store<AdminState>,
    private authStore: Store<AuthState>,
  ) {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      })

  }

  ngOnInit(): void {

  }

  reasonSelected(event: any) {
    this.selectedOption = event.value;
  }

  getCSVFile(typeData: any) {
    this.adminStore.dispatch(GetUserCsvFile({params:{type:typeData.id, csvName: typeData.csvName}}));
  }

  checkData() {
    if (this.currentUser.role !== 'MANAGEMENT' && this.currentUser.role !== 'IT') {
      return this.currentUser.permissions.some((item: any) => item.menu === 'export' && item.permissionType.some((per: any) => per === 'write'));
    } else {
      return this.currentUser.role === 'MANAGEMENT' || this.currentUser.role === 'IT';
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }

}
