<div class="orders-list">
  <div class="orders-list-title">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="back d-flex align-items-center">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer  d-md-block d-none"
               [routerLink]="['/admin', 'dashboard']"/>
          <p class="title-back mb-0 fs-16 cursor-pointer  d-md-block d-none"
             [routerLink]="['/admin', 'dashboard']">{{'back' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="orders-list-wrapper">
    <h2 class="fs-32 fw-bold ps-sm-0 ps-4">{{'carts' | translate}}</h2>
    <div class="custom-table">
      <kwot-custom-table
        #customTableComponent
        [customOptions]="customDatatableSettings"
        [ajaxFunction]="getAllOrders"
        [tableHead]="tableHead" [tableBody]="tableBody">
      </kwot-custom-table>
      <ng-template #tableHead>
        <thead *ngIf="!isMobile">
        <tr>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'buyer' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'buyer_type' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'total_products' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'payment_page_visits' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'payment_attempts' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'last_updated' | translate}}
            </p>
          </td>
        </tr>
        </thead>
      </ng-template>
      <ng-template #tableBody>
        <tbody *ngIf="cartsList.length <= 0">
        <tr>
          <td colspan="7">{{'no_data_found' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="cartsList.length > 0  && !isMobile">
        <tr *ngFor="let order of cartsList">
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details">
              {{order?.userId?.firstName}} {{order?.userId?.lastName}}
              <br/>
              {{order?.userId?.email}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details">
              {{order?.userId?.role === 'USER' ? 'Registered' : 'Guest'}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{order.items.length}} <span class="ms-3 fa fa-eye cursor-pointer" (click)="showProducts(order)"></span>
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{order.paymentPageVisit || 0}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{(order.paymentAttempts && order.paymentAttempts.length) || 0}} <span
              *ngIf="((order.paymentAttempts && order.paymentAttempts.length) || 0) !== 0"
              class="ms-3 fa fa-eye cursor-pointer" (click)="showAttempts(AttemptsModel, order)"></span>
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{order.updatedAt | date: 'dd/MM/yyyy'}}
            </p>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="cartsList.length > 0  && isMobile">
        <tr *ngFor="let order of cartsList">
          <td colspan="9" class="order-details w-100 p-3">
            <div>
              <p class="mb-0 fs-16 cursor-pointer store-content-details">
                {{'buyer' | translate}}: -
                {{order?.userId?.firstName}} {{order?.userId?.lastName}} ({{order?.userId?.email}})
              </p>
            </div>
            <div>
              <div class="d-flex">
                <p class="mb-0 fs-16 store-content-details">
                  {{'total_products' | translate}}:- {{order.items.length}} <span class="ms-3 fa fa-eye cursor-pointer"
                                                                (click)="showProducts(order)"></span>
                </p>
                <p class="mb-0 fs-16 store-content-details">
                  {{'payment_page_visits' | translate}}:- {{order.paymentPageVisit || 0}}
                </p>
                <p class="mb-0 fs-16 store-content-details">
                  {{'payment_attempts' | translate}}:- {{(order.paymentAttempts && order.paymentAttempts.length) || 0}} <span
                  class="ms-3 fa fa-eye cursor-pointer"
                  *ngIf="((order.paymentAttempts && order.paymentAttempts.length) || 0) !== 0"
                  (click)="showAttempts(AttemptsModel, order)"></span>
                </p>

                <p class="mb-0 fs-16 store-content-details ms-2">
                  {{'last_updated' | translate}}:- {{order.updatedAt | date: 'dd/MM/yyyy'}}
                </p>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </ng-template>
      <div class="custom-pagination" *ngIf="dataCount !== 0">
        <pagination [boundaryLinks]="true" [rotate]="true" [totalItems]="dataCount"
                    [ngModel]="queryParams.page"
                    [itemsPerPage]="10" [maxSize]="5" (pageChanged)="changePage($event)"></pagination>
      </div>
    </div>
    <ng-template #AttemptsModel>
      <div class="audit-logs-detail">
        <div class="modal-header justify-content-center">
          <h4 class="modal-title fs-16 fw-bold text-center mb-0">{{'payment_details' | translate}}</h4>
          <button type="button" class="btn-close m-0" (click)="modalRef?.hide()"></button>
        </div>
        <div class="modal-body pt-0">
          <div class="account-content" *ngFor="let attempt of showCartAttempts.paymentAttempts; let i = index">
            <div class="d-flex">
              <h6 class="fs-16 title fw-bold">#{{i + 1}}</h6>
            </div>
            <div class="d-flex">
              <h6 class="ms-auto fs-16 title w-100">
                <ngx-json-viewer [json]="attempt" [expanded]="false"></ngx-json-viewer>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
