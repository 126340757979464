export const environment = {
  production: false,
  apiUrl: 'https://yt40strhwi.execute-api.us-east-1.amazonaws.com/dev/v1',
  google_key: '341078272776-31vppl0h663ol2ecq0b0jlk608qqhl0j.apps.googleusercontent.com',
  google_recaptcha_site_key: '6LdZ6zkeAAAAADsF4kZjyjNhR7jnfiTGzrPEhlnG',
  google_recaptcha_secret: '6LdZ6zkeAAAAAJnO4XD-th0HYQFtYKTjx18yEP4d',
  type: 'admin',
  ip_api :'https://api.ipgeolocation.io/ipgeo?apiKey=386b26cb610a499aa8e7ab71e07dbf71',
  s3Bucket: 'kwot.api',
  websocket: 'wss://6ljbqkqxd6.execute-api.us-east-1.amazonaws.com/dev',
};
