<ng-template #helpDesk>
  <div class="help me-3 d-md-block d-none">
    <a class="mb-0 fw-medium need-help cursor-pointer">{{'need_help' | translate}}</a>
  </div>
</ng-template>
<kwot-header [currentUser]="currentUser" [helpDesk]="helpDesk"></kwot-header>
<div class="side-menu">
  <div class="main-menu">
    <ul class="p-0 m-0">
      <li [ngClass]="{'active': (!currentRoute || currentRoute === 'dashboard')}">
        <a [routerLink]="['/admin', 'dashboard']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/dashboard-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/dashboard-light-icon.svg" alt="">
          <span>{{'dashboard' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'sellers'}" *ngIf="checkData('sellers')">
        <a [routerLink]="['/admin', 'sellers']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/sales-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/sales-light-icon.svg" alt="">
          <span>{{'sellers' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'buyers'}" *ngIf="checkData('buyers')">
        <a [routerLink]="['/admin', 'buyers']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/total-buyers-dark.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/total-buyers-light.svg" alt="">
          <span>{{'buyers' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'orders'}"  *ngIf="checkData('orders')">
        <a [routerLink]="['/admin', 'orders']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/order-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/order-light-icon.svg" alt="">
          <span>{{'orders' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'rfqs'}"  *ngIf="checkData('rfqs') && isB2B" >
        <a [routerLink]="['/admin', 'rfqs']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/rfq-dark.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/rfq-light.svg" alt="">
          <span>{{'negotiation' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'carts'}" *ngIf="checkData('carts')">
        <a [routerLink]="['/admin', 'carts']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/order-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/order-light-icon.svg" alt="">
          <span>{{'carts' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'products'}" *ngIf="checkData('products')">
        <a [routerLink]="['/admin', 'products']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/products-icon-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/products-icon-light.svg" alt="">
          <span>{{'products_no_bracket' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentRoute === 'gift' }" *ngIf="checkData('giftCards')">
        <a [routerLink]="['/admin', 'gift']" class="fs-16">
          <img
            class="dark-icon me-3"
            height="30px"
            width="30px"
            src="/assets/images/icons/gift_dark.png"
            alt=""
          />
          <img
            class="light-icon me-3"
            height="30px"
            width="30px"
            src="/assets/images/icons/gift_light.png"
            alt=""
          />
          <span>{{ 'gift_cards' | translate }}</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentRoute === 'email-templates' }" *ngIf="checkData('emailTemplates')">
        <a [routerLink]="['/admin', 'email-templates']" class="fs-16">
          <img
            class="dark-icon me-3"
            height="30px"
            width="30px"
            src="/assets/images/icons/email_dark.png"
            alt=""
          />
          <img
            class="light-icon me-3"
            height="30px"
            width="30px"
            src="/assets/images/icons/email_light.png"
            alt=""
          />
          <span>{{ 'email_templates' | translate }}</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentRoute === 'affiliate-users' }" *ngIf="checkData('affiliateUsers')">
        <a [routerLink]="['/admin', 'affiliate-users']" class="fs-16">
          <img
            class="dark-icon me-3"
            height="30px"
            width="30px"
            src="/assets/images/icons/total-buyers-dark.svg"
            alt=""
          />
          <img
            class="light-icon me-3"
            height="30px"
            width="30px"
            src="/assets/images/icons/total-buyers-light.svg"
            alt=""
          />
          <span>{{'affiliate_users' | translate }}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'inbox'}" *ngIf="checkData('inbox')">
        <a [routerLink]="['/admin', 'inbox']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/inbox-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/inbox-light-icon.svg" alt="">
          <span>{{'inbox' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'messages'}" *ngIf="checkData('messages')">
        <a [routerLink]="['/admin', 'messages']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/inbox-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/inbox-light-icon.svg" alt="">
          <span>{{'messages' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': (currentRoute === 'reviews')}" *ngIf="checkData('reviews')">
        <a [routerLink]="['/admin', 'reviews']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/review-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/review-light.svg" alt="">
          <span> {{'reviews' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'finance'}" *ngIf="checkData('finance')">
        <a [routerLink]="['/admin', 'finance']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/financial-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/financial-light-icon.svg" alt="">
          <span>{{'finances' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'staff'}" *ngIf="currentUser.role === 'MANAGEMENT' || currentUser.role === 'IT'">
        <a [routerLink]="['/admin', 'staff']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/staff-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/staff-light-icon.svg" alt="">
          <span>{{'staff' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'categories'}" *ngIf="checkData('categories')">
        <a [routerLink]="['/admin', 'categories']" class="fs-16">
          <i class="dark-icon me-3 fa fa-list fs-22"></i>
          <i class="light-icon me-3 fa fa-list fs-22"></i>
          <span>{{'categories' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'promo-code'}" *ngIf="checkData('promoCodes')">
        <a [routerLink]="['/admin', 'promo-code']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/promo-icon-dark.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/promo-icon-light.svg" alt="">
          <span>{{'promo_codes' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'ads'}" *ngIf="checkData('kwotAds')">
        <a [routerLink]="['/admin', 'ads']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/ads-dark-icon.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/ads-light-icon.png" alt="">
          <span>{{'kwot_ads' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'site-settings'}"  *ngIf="checkData('siteSettings')">
        <a [routerLink]="['/admin', 'site-settings']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/settings-icon-dark.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/settings-icon-light.svg" alt="">
          <span>{{'site_settings' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'currency-rate'}"  *ngIf="checkData('currencyRate')">
        <a [routerLink]="['/admin', 'currency-rate']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'currency_rate' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'shipping-cost'}"  *ngIf="checkData('shippingCost')">
        <a [routerLink]="['/admin', 'shipping-cost']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'shipping_cost' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'policy'}"  *ngIf="checkData('policy')">
        <a [routerLink]="['/admin', 'policy']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'policy' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'audit-logs'}"  *ngIf="checkData('auditLogs')">
        <a [routerLink]="['/admin', 'audit-logs']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'audit_logs' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'revisions'}"  *ngIf="checkData('revisions')">
        <a [routerLink]="['/admin', 'revisions']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'revisions' | translate}}</span>
        </a>
      </li>

      <li [ngClass]="{'active': currentRoute === 'subscription-plans'}"  *ngIf="checkData('subscriptionPlans')">
        <a [routerLink]="['/admin', 'subscription-plans']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'subscription_plans' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'export'}"  *ngIf="checkData('export')">
        <a [routerLink]="['/admin', 'export']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'export' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'crons'}"  *ngIf="checkData('cron')">
        <a [routerLink]="['/admin', 'crons']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'cron' | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
