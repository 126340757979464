import {createAction, props} from "@ngrx/store";

const AdminVendorActions = {
  GET_NOTIFICATIONS: '[ADMIN_VENDOR_SHARED] Get Notifications',
  GET_NOTIFICATIONS_SUCCESS: '[ADMIN_VENDOR_SHARED] Get Notifications Success',
  GET_NOTIFICATIONS_ERROR: '[ADMIN_VENDOR_SHARED] Get Notifications Error',
  UPDATE_NOTIFICATIONS: '[ADMIN_VENDOR_SHARED] Update Notifications',
  UPDATE_NOTIFICATIONS_SUCCESS: '[ADMIN_VENDOR_SHARED] Update Notifications Success',
  UPDATE_NOTIFICATIONS_ERROR: '[ADMIN_VENDOR_SHARED] Update Notifications Error',
  READ_NOTIFICATIONS: '[ADMIN_VENDOR_SHARED] Read Notifications',
  READ_NOTIFICATIONS_SUCCESS: '[ADMIN_VENDOR_SHARED] Read Notifications Success',
  READ_NOTIFICATIONS_ERROR: '[ADMIN_VENDOR_SHARED] Read Notifications Error',

  RESET_ADMIN_VENDOR_STATE: '[ADMIN_VENDOR_SHARED] Reset Admin Vendor State'
}

export const GetNotifications = createAction(AdminVendorActions.GET_NOTIFICATIONS, (params: any = {}) => params);
export const GetNotificationsSuccess = createAction(AdminVendorActions.GET_NOTIFICATIONS_SUCCESS, props<{ notifications: any }>());
export const GetNotificationsError = createAction(AdminVendorActions.GET_NOTIFICATIONS_ERROR, props<{ error: string }>());

export const UpdateNotifications = createAction(AdminVendorActions.UPDATE_NOTIFICATIONS, (params: any = {}) => params);
export const UpdateNotificationsSuccess = createAction(AdminVendorActions.UPDATE_NOTIFICATIONS_SUCCESS, props<{ notificationSeen: any }>());
export const UpdateNotificationsError = createAction(AdminVendorActions.UPDATE_NOTIFICATIONS_ERROR, props<{ error: string }>());

export const ReadNotification = createAction(AdminVendorActions.READ_NOTIFICATIONS, props<{ notificationId: string }>());
export const ReadNotificationSuccess = createAction(AdminVendorActions.READ_NOTIFICATIONS_SUCCESS);
export const ReadNotificationError = createAction(AdminVendorActions.READ_NOTIFICATIONS_ERROR, props<{ error: string }>());

export const ResetAdminVendorState = createAction(AdminVendorActions.RESET_ADMIN_VENDOR_STATE, (params: any = {}) => params);
